.scheduled_holiday-page {


    #scheduled_holiday-setting-modal {
        #scheduled_holiday-setting-modal-year {
            height: 37px;
            bottom: auto;
        }
        .selectable-date {
            cursor: pointer;
        }
        .national-holiday {
            background-color: $gray-300;
            cursor: pointer;
        }
        .prescribed-holiday {
            background-color: rgb(215, 215, 255);
            cursor: pointer;
        }
        .legal-holiday {
            background-color: rgb(255, 215, 215);
            cursor: default;
        }
        #scheduled_holiday-setting-modal-calendar-marker {
            border-top: 1px solid $gray-400;
            padding-top: 30px;
        }
        .calendar-marker {
            label {
                height: 20px;
                line-height: 20px;
                .maker {
                    display: inline-block;
                    vertical-align: bottom;
                    width: 30px;
                    height: 20px;
                    margin-right: 5px;
                }
            }
        }
        .yearly-holiday-calendar {
            table {
                text-align: center;
                input {
                    display: none;
                }
            }
        }
    }
}