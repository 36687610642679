.header-top{
    top:-1px;
}
.sticky-top {
    background-color: $white;
}
.sticky-head-table {
    border-collapse: separate;
    border-spacing: 0;
}

.remote_rollcall-page {
    .navbar-search-block {
        display: none;
    }
}

.it_rollcall-page {
    .navbar-search-block {
        display: none;
    }
}

#remote_rollcall-join-modal, #remote_rollcall-api-join {
    .modal-body {
        min-height: 850px;
        max-height: 850px;
    }
    .detail-view {
        #rollcall-detail-modal {
            width: 1200px;
        }
        #vert-tabs-right-daily_check table {
            th,td {
                white-space: nowrap;
                text-align: center;
            }
        }
    }
    .timer-view {
        position: absolute;
        top: 10px;
        right: 20px;
        font-size: 1.5rem;
        background-color: $white;
        border-radius: 5px;
        padding: 0 8px;
        z-index: 2147483647;
    }
    .recording-view {
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 1rem;
        padding: 0 8px;
        z-index: 2147483647;
        color: #e62b2b;
        animation-name: blinking;
        animation-duration: .8s;
        animation-direction: alternate;
        animation-iteration-count: 8;
        i {
            margin-right: 8px;
        }
    }
    .meeting-view {
        position: relative;
        .video-view {
            .video-preview {
                display: block;
                width: 100%;
                max-height: 360px;
                object-fit: contain;
                overflow-clip-margin: content-box;
                overflow: clip;
                background: #333;
            }
        }
        .video-slide {
            position: absolute;
            bottom: 0;
            white-space: nowrap;
            overflow-x: scroll;
            overflow-y: visible;
            .video-preview {
                display: none;
                &[autoplay=true] {
                    display: inline-block;
                    width: 20%;
                    background: #ddd;
                    border: solid #333 1px;
                }
            }
        }
    }
    .device-select {
        .invalid {
            .select2-container .select2-selection {
                background-color: #f6b3b3;
                border-color: #f6b3b3;
            }
        }
    }
    #remote_rollcall-join-modal-form {
        #remote_rollcall-join-modal-video-preview {
            display: flex;
            width: 100%;
        }
        #remote_rollcall-join-modal-meeting-information {
            margin-top: 10px;
            .alc-img {
                max-width: 100%;
                max-height: 150px;
            }
        }
        .bs-stepper {
            .bs-stepper-label {
                font-size: 0.8rem; 
                i {
                    margin-top: 10px;
                }
            }
            .step-trigger {
                padding: 5px;
            }
        }
        .bs-stepper-content {
            border-radius: 8px;
            border: 2px solid $bss_color_logo_blue;
        }
    }
    #remote_rollcall-join-modal-complete {
        background-color: $white;
        border-color: $success;
        color: $success;
    }
    #remote_rollcall-join-modal-cancel {
        background-color: $white;
        border-color: $warning;
        color: $warning;
    }
    #drawer-toggle {
        width: 5%;
        #drawer-open {
            padding: 2px;
            line-height: 1.5rem;
            writing-mode: vertical-rl;
            border-right: 1px solid #ddd;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            border-radius: 5px;
            background: #f8f9fa;
        }
        #drawer-content {
            min-height: 800px;
            max-height: 800px;
            overflow: auto;
            padding: 5px 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            background: #f8f9fa;
    
            #external-draggable {
                div {
                    position: relative;
                    font-size: 0.85rem;
                    border-radius: 5px;
                    background: #4c87c7;
                    margin: 0 0 5px 0;
                    padding: 2px 20px 2px 5px;
                    color: #fff;
                    line-height: 1.2rem;
    
                    &::after {
                        position: absolute;
                        display: block;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto 5px;
                        content: "\f0da";
                        font-family: "Font Awesome 5 Free","Font Awesome 5 Brands";
                        font-weight: bold;
                        color: #fff;
                        font-size: 1rem;
                        height: 1rem;
                    }
                }
                div.external-item {
                    cursor: pointer;
                }
                div.external-draggable-item {
                    cursor: move;
                }
    
            }
        }
    }
}
#remote_rollcall-join-modal-meeting-detail {
    max-height: 850px;
    overflow-y: scroll;
}
#remote_rollcall-join-modal-meeting-information {
    max-height: 850px;
    overflow-y: scroll;
    .form-control {
        font-size: 1.2rem;
    }
    textarea {
        min-height: 50px;
    }
    .rollcall-type-switch {
        label[data-label="×"] {
            padding-top: 2px !important;
        }
        label {
            font-size: 1.2rem;
            line-height: 1.0;
            font-weight: bold !important;
        }
    }
}
#remote_rollcall-recognition-modal, #remote_rollcall-facial-modal {
    &-video {
        transform: scaleX(-1);
    }
}
#remote_rollcall-recognition-modal {
    .recognition-message {
        padding: 10px;
        text-align:center;
        font-size: 2em;
        font-weight:bold;
        color: red;
    }
}
#remote_rollcall-facial-modal {
    .recognition-message {
        padding: 10px;
        text-align:center;
        font-size: 1.5em;
        font-weight:bold;
        color: red;
    }
}
.remote_rollcall-recognition-modal-guideline-outer {
    border: solid 2px $white;
    width: 480px;
    height: 480px;
    position: absolute;
    top: 25px;
    margin-left: 10px;
    
    .remote_rollcall-recognition-modal-guideline-inner {
        width: 94%;
        height: 94%;
        margin-top: 3%;
        margin-left: 3%;

        background:
        linear-gradient(to right, $white 2px, transparent 2px) 0 0,
        linear-gradient(to right, $white 2px, transparent 2px) 0 100%,
        linear-gradient(to left, $white 2px, transparent 2px) 100% 0,
        linear-gradient(to left, $white 2px, transparent 2px) 100% 100%,
        linear-gradient(to bottom, $white 2px, transparent 2px) 0 0,
        linear-gradient(to bottom, $white 2px, transparent 2px) 100% 0,
        linear-gradient(to top, $white 2px, transparent 2px) 0 100%,
        linear-gradient(to top, $white 2px, transparent 2px) 100% 100%;

        background-repeat: no-repeat;
        background-size: 7% 7%;
    }
}
.modal-daily_check {
    max-width: inherit;
    position: relative;
    width: 50%;
}
@media screen and (max-width: 575px) {
    .modal-daily_check {
        width: auto;
    }
}
@keyframes blinking {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}