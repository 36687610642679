.modal-xxl {
    max-width: 1480px;
}
.modal-w50 {
    max-width: 50%;
}
.modal-w80 {
    max-width: 80%;
}
.modal-w96 {
    max-width: 92%;
}
.modal-h50 {
    max-height: 50%;
}
.modal-dialog.position-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    margin: auto;
    pointer-events: none;

    // 表示モーダルが「お知らせ設定変更」の場合
    &[target="notification"] {
        max-width: inherit;
        width: 80%;
    }

    .modal-content {
        pointer-events: all;

        // 表示モーダルが「お知らせ設定変更」の場合
        &[target="notification"] {
            height: 800px;
        }
    }
}

.modal-body {
    overflow-y: scroll;
    &:not([target]){
        max-height: 550px;
    }

    // 表示モーダルが「お知らせ設定変更」の場合
    &[target="notification"]{
        max-height: 900px;
    }
}
.modal-body_task{
    max-height: 550px;
    overflow-y: scroll;
    padding: 0;
}
.modal-body_delete{
    text-align:center;
    height: 90px;
    max-height: 550px;
    padding: 0;

    p{
        margin: 35px auto;
    }
}
//出退勤管理　出退勤一覧（月明細）の高さを調節
.modal-body-detail {
    max-height: 90vh;
    margin-bottom: 2px;
    padding-left: 20px;
    padding-right: 20px;
}
//出退勤管理　月グラフ・年グラフの高さを調節
.modal-body-chart {
    height: 85vh;
    overflow-y: scroll;
}
//出退勤管理　出退勤編集モーダルの高さを調節
.modal-body-setting {
    min-height: 555px;
    overflow-y: scroll;
}
// ファイル管理
.modal-files {
    max-width: inherit;
    position: relative;
    width: 60%;
}
