.rollcall_recording_config-page {
    .side-bar {
        min-width: 400px;
        height: 764px;
        display: flex;
        margin-right: 7.5px;
        max-height: 764px;
        overflow-y: scroll;
        scrollbar-color: #d0d0d0 #bebebe;
        .company-list-item {
            display: flex;
            justify-content: space-between;
            list-style: none;
            padding: 0.75rem 1.25rem;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.125);
            color: #212529;
            .contract-count {
                min-width: 120px;
                text-align: right;
                font-size: 12px;
                .contract-count-num {
                    font-size: 16px;
                }
            }
        }
        .company-list-item:hover {
            background-color: #F5F5F5;
        }
        .company-list-item.active {
            background-color: #428bca;
            color: #fff;
        }
    }
    .main-area {
        width: 100%;
        .usage-count {
            border: 1px solid #ddd;
            background: #f8f9fa;
        }
        .header-top {
            z-index: 2 !important;
        }
        th.checkbox {
            width: 110px;
        }
        td.recording-flag {
            text-align: center;
            width: 250px;
            .btn.active {
                pointer-events: none;
            }
        }
        td.recording-flag.pe-none {
            pointer-events: none;
        }
    }
    // 虫眼鏡を消す
    .nav-link[data-widget="navbar-search"] {
        display: none;
    }
    .navbar-search-block {
        display: none;
    }
}