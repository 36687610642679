.blood_pressure-page {

    .blood_pressure-list th{
        text-align: center;
    }
    .blood_pressure-list thead tr,
    .blood_pressure-list tbody tr td {
        font-size: 1.2em;
        border: 1px solid #c1c6ca;
        text-align: left;
    }

    #blood_pressure-detail-modal-daialog {
        max-width: 1190px
    }

    .header-top{
        top:-1px;
    }

    .sticky-footer {
        td {
            position: -webkit-sticky;
            background-color: $gray-100 !important;
            bottom: 1px;
            position: sticky;
            font-size: 1.2em;
            border: 1px solid #c1c6ca;
            border-bottom: 1px solid #c1c6ca;
        }
    }

    .sticky-footer-th1 {
        position: -webkit-sticky;
        background-color: $bss_color_logo_blue !important;
        color: $bss_color_background  !important;
        bottom: 1px;
        position: sticky;
        text-align: center;
        border: 1px $bss_color_background solid;
    }

    .sticky-footer-th2 {
        position: -webkit-sticky;
        background-color: $bss_color_logo_blue !important;
        color: $bss_color_background  !important;
        bottom: 80px;
        position: sticky;
        /* tbody内のセルより手前に表示する */
        z-index: 1;
        text-align: center;
        border: 1px $bss_color_background solid;
    }

    .sticky-footer-th3 {
        position: -webkit-sticky;
        background-color: $bss_color_logo_blue !important;
        color: $bss_color_background  !important;
        bottom: 44px;
        position: sticky;
        /* tbody内のセルより手前に表示する */
        z-index: 1;
        text-align: center;
        border: 1px $bss_color_background solid;
    }

    #chart-canvas {
        max-height: 75vh;
    }

    .detail-overflow {
        max-height: 75vh;
    }
    /* ブレイクポイント強制 */
    @media screen and (max-width: 996px) {
        .detail-overflow {
            max-height: 65vh;
        }
    }

}
