.dashboard_data-page {
    .list-area {
        td {
            cursor: auto !important;
        }
    }
    .td-common{
        padding:6px 30px;
        white-space: nowrap;
        width: 1px;
        text-align: center;
    }
    .btn-task-close{
        background-color: $gray-600;
        filter: grayscale(100%);
        pointer-events: none;
        color: white;
    }
    .information{
        .information_category{
            padding-left: 7.5px;
        }
        .information_category .btn-group{
            flex-wrap: wrap !important;
        }
    }
    .task {
        .btn-task-close {
            pointer-events: auto;
        }
    }
}
