.vehicle-page {
    .header-top {
        top: -1px;
    }

    .dropdown-menu {
        margin-right: 93px;
    }
    .col-md-1_25 {
        -webkit-flex: 0 0 10.4%;
        -ms-flex: 0 0 10.4%;
        flex: 0 0 10.4%;
        max-width: 10.4%;
        min-width: 120px;
        padding: 0 2px;
    }

    @media screen and (min-width: 768px) {
        .col-md-1_25 {
            -webkit-flex: 0 0 10.4%;
            -ms-flex: 0 0 10.4%;
            flex: 0 0 10.4%;
            max-width: 10.4%;
            padding: 0 2px;
        }
    }

    .col-md-1_25 .datetimepicker-input {
        font-size: 0.55em;
    }

    .bs-stepper .step-trigger.disabled,
    .bs-stepper .step-trigger:disabled {
        opacity: 1;
    }

    label[for="search-form-status"],
    label[for="search-form-certificate_home_business"],
    label[for="search-form-certificate_vehicle_type"],
    label[for="search-form-tow_type"],
    label[for="search-form-alert"] {
        display: block;
        width: 115px;
    }

      #search-form {
        div.btn-group-toggle {
          label.btn {
            min-width: 30px;
          }
        }
      }

      .vehicle-content {
        margin-right: 20px;
        margin-bottom: 10px;

        .book {
            position: relative;
            display: inline-block;
            border-radius: 7px;
            height: 210px;
            width: 180px;
            margin-bottom: auto;
            cursor: pointer;

            .notice-count {
                top: 5px;
                right: 5px;
            }

            img.book_image {
                height: 100%;
                width: 100%;
            }

            img.vehicle_image {
                display: flex;
                position: absolute;
                top: 69px;
                left: 18px;
                width: 158px;
                height: 97px;
                border-radius: 5px;
                object-fit: cover;
                border: 1px solid #c3c3c3;
            }

            img.vehicle_license_image {
                display: flex;
                position: absolute;
                top: 168px;
                left: 130px;
                width: 45px;
                height: 32px;
                z-index: 1;
            }

            .info {
                position: absolute;
                top: 3px;
                left: 15px;
                margin-bottom: 1px;
            }

            dd {
                height: 22px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: auto;
                display: inline-block;
                width: 16ch;
            }

            dd.status {
                text-align: left;
                padding-left: 8px;
            }
        }

        .book:hover {
            background-color: #ffffe3 !important;
            color: #000000 !important;
        }

        .period {
            font-size: 0.73rem;
            height: 20px;
            text-align: center;
        }
    }

    .modal-vehicle {
        max-width: inherit;
        position: relative;
        width: 90%;

        .no-disp-unless-scrap {
            display: none;
        }
    }

    @media screen and (max-width: 575px) {
        .modal-vehicle {
            width: unset;
        }
    }

    .vehicle-detail-modal {
        width: 90%;
        #vehicle-detail-dismiss {
          position:relative;
          top:100px;
        }
    }

    .certificate-vehicle-type-container,
    .certificate-use-container,
    .certificate-home-business-container,
    .tow-type-container,
    .loading-vehicle-type-container {
        margin-top: 4px;
    }

    #vehicle-detail-modal {
        .modal-body {
            overflow-y: auto;
            max-height: 900px;

            #refuel,
            #daily_check {
                table th {
                    vertical-align: middle;
                }
            }

            #accident,
            #equipment,
            #violation,
            #refuel,
            #daily_check {
                width: 100%;
                overflow-x: auto;

                table {
                    th {
                        white-space: nowrap;
                        text-align: center;
                        background-color: $white;
                    }

                    td {
                        white-space: nowrap;
                        text-align: center;
                    }

                    td.ellipsis {
                        max-width: 130px;

                        p {
                            height: 22px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            margin-bottom: auto;
                            display: inline-block;
                            width: 100%;
                        }
                    }
                }
            }

            .image-preview.base_vehicle_image-preview {
                width: 380px;
                height: 215px;
            }

            .image-preview.base_vehicle_sub_image1-preview,
            .image-preview.base_vehicle_sub_image2-preview,
            .image-preview.base_vehicle_sub_image3-preview,
            .image-preview.base_vehicle_sub_image4-preview {
                width: 250px;
                height: 150px;
            }

            //@media screen and (min-width: 1200px) and (max-width: 1399px) {
            //    .image-preview.base_vehicle_sub_image-preview {
            //        transform: scale(0.8);
            //    }
            //}
        }

        // 詳細のヘッダー
        .modal-vehicle-header {
            padding: 0;
        }

        // 詳細のタイトル
        .modal-vehicle-title {
            padding: 16px;
        }

        // 印刷ボタン
        .modal-vehicle-print {
            padding: 9px 25px;
        }

        .modal-content:not(.files-dispose-modal .modal-content) {
            height: 900px;
        }

        #content {
            height: 100%;

            .tab-pane {
                min-height: 200px;
            }

            #detail-tabs {
                max-height: 680px;

                div.nav-tabs {
                    flex-direction: column;
                    border-left: 1px solid #dee2e6;
                    border-bottom: none;
                }
            }
        }

        @media screen and (max-width: 1199px) {
            #content {
                .reverse {
                    display: flex;
                    flex-direction: column-reverse;

                    .tab-content {
                        margin-top: 20px;
                    }
                }

                #detail-tabs {
                    padding: 0 !important;

                    div.nav-tabs {
                        flex-direction: unset;
                        border-bottom: 1px solid #dee2e6;
                    }
                }
            }
        }

        #left-side {
            min-height: 100%;
            margin-bottom: 20px;
            border-right: 1px solid #dee2e6;
        }

        @media screen and (max-width: 1199px) {
            #left-side {
                min-height: unset;
                border-right: none;
            }
        }

        #right-side {
            height: 100%;
        }

        @media screen and (max-width: 1199px) {
            #right-side {
                height: unset;
                min-height: 200px;
            }
        }

        img.vehicle_image {
            width: 380px;
            height: 215px;
            object-fit: contain;
            margin-bottom: 10px;
            border-radius: 5px;
            box-shadow: 0 0 1px 1px rgb(0 0 0 / 30%);
        }

        img.vehicle_sub_image {
            width: 250px;
            height: 150px;
            object-fit: contain;
            margin-bottom: 10px;
            border-radius: 5px;
            box-shadow: 0 0 1px 1px rgb(0 0 0 / 30%);
        }

        @media screen and (min-width: 1200px) and (max-width: 1399px) {
            img.vehicle_sub_image {
                transform: scale(0.8);
            }
        }

        fieldset {
            border: 1px solid #c3c3c3;
            border-radius: 4px;
            padding: 5px 5px 10px 5px;
            margin-right: 9px;
            margin-bottom: 13px;
            width: 100%;

            legend {
                width: auto;
                font-size: 14px;
                font-weight: bold;
                margin-left: 5px;
                margin-bottom: 0;
                padding-left: 5px;
                padding-right: 5px;
            }

            .info {
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 2px;
            }

            dd {
                height: 25px;
                margin-bottom: auto;
                padding-left: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            dd.pdf {
                height: 35px;
            }

            dd.holding {
                overflow: visible;
            }

            dt.error,
            dd.error {
                font-weight: bold;
                color: red;
            }

            div.btn-right {
                right: 0;
            }
        }

        fieldset#registration,
        fieldset#specifications {
            .info {
                width: 49%;
            }
        }

        fieldset#remarks {
            .info {
                width: 100%;
            }
        }

        #vert-tabs-right-insurance {
            th.error {
                font-weight: bold;
                color: red;
            }

            td:nth-child(2) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 300px;
            }

            @media screen and (min-width: 1200px) and (max-width: 1600px) {
                td:nth-child(2) {
                    max-width: 200px;
                }
            }
        }

        #other.info {
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 2px;

            dd {
                height: 25px;
                margin-bottom: auto;
            }

            dt.error,
            dd.error {
                font-weight: bold;
                color: red;
            }
        }

        #inspection {
            .table {
                width: 100%;
            }

            th {
                background-color: $white;
            }

            $i: 2;

            @while $i <=5 {
                span.w#{$i} {
                    width: #{$i}rem;
                }

                $i: $i + 1;
            }
        }

        .nav-tabs {
            line-height: 1.2rem;

            .nav-link {
                border-color: #dee2e6 #dee2e6 #dee2e6 transparent;

                &.active {
                    background-color: $blue;
                    color: $white;
                }
            }
        }
    }

    #vehicle-refuel-list-modal {
        .vehicle-refuel-list-modal-padding {
            padding: 30px;
        }
    }

    button.refuel-modal-close-button {
        position: relative;
        left: -20px;
    }

    #vehicle-refuel-modal,
    #vehicle-setting-modal {
        overflow-y: auto;
        .equipment-close {
            cursor: pointer !important;

            i {
                color: gray;
            }
        }

        .modal-body {
            overflow-y: scroll;
            max-height: 900px;

            .card-body {
                padding: 0;
            }
        }

        .modal-body.transfered {
            input {
                pointer-events: none;
            }
        }

        .modal-content:not(.files-dispose-modal .modal-content) {
            height: 900px;
        }

        #base-part-submit,
        #certificate-part-submit,
        #equipment-part-submit,
        #insurance-part-submit {
            button[data-confirm-step] {
                color: #fff;
                background-color: #4c87c7;
            }
        }

        .image-preview {
            width: 270px;
            height: 170px;

            img {
                width: 100%;
                object-fit: contain;
            }
        }

        .sub_image1-preview,
        .sub_image2-preview,
        .sub_image3-preview,
        .sub_image4-preview {
            width: 174px;
            height: 110px;
        }

        .image-setting {
            .input-group-btn {
                width: 150px;

                .btn {
                    width: 100%;
                }
            }
        }

        .btn-group-toggle {
            .px-3 {
                min-width: 71px;
            }
        }

        .insurance-list-area {
            input {
                min-width: 100px;
            }
        }

        .lease-rental {
            padding: 0;
            margin-top: auto;

            &-button {
                margin-bottom: 0px !important;
            }
        }

        .parent-select-focused {
          border-radius:6px;
          border:solid 2px;
        }

     }


    #vehicle-transfer-modal {
        .modal-vehicle-transfer {
            max-width: inherit;
            position: relative;
            width: 53%;
        }

        .modal-body {
            overflow-y: scroll;
            max-height: 780px;

            .card-body {
                padding: 0;
            }
        }

        .modal-content {
            height: 780px;
        }

        fieldset#before,
        fieldset#after {
            border: 1px solid #c3c3c3;
            border-radius: 5px;
            padding: 15px;
            margin-right: 10px;

            legend {
                text-align: center;
                width: auto;
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .diag-list-gap {
            position: relative;
            height: auto;
            margin: auto 0;
            flex: 0 0 3.333333%;

            .diag-list-gap-img {
                display: flex;
                flex-direction: column;
                width: 70px;
                height: 70px;
                margin: 110px 5px 110px 0;
            }
        }
    }


    #vehicle-use-start-date-modal {
        .modal-vehicle-use-start-date {
            max-width: inherit;
            position: relative;
            width: 53%;
        }

        .modal-body {
            overflow-y: scroll;
            min-height: 500px;

            .card-body {
                padding: 0;
            }
        }

        .modal-content {
            min-height: 500px;
        }

        fieldset#before,
        fieldset#after {
            border: 1px solid #c3c3c3;
            border-radius: 5px;
            padding: 15px;
            margin-right: 10px;

            legend {
                text-align: center;
                width: auto;
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .diag-list-gap {
            position: relative;
            height: auto;
            margin: auto 0;
            flex: 0 0 3.333333%;

            .diag-list-gap-img {
                display: flex;
                flex-direction: column;
                width: 70px;
                height: 70px;
                margin: 110px 5px 110px 0;
            }
        }
    }

    #equipment_history-modal,
    #vehicle-history-modal {

        .detail-overflow {
            max-height: 50vh;
        }

        table.sticky-head-table {
            border-collapse: separate;
            border-spacing: 0;
        }

        .modal-vehicle-transfer {
            max-width: inherit;
            position: relative;
            width: 53%;
        }

        .modal-body {
            overflow-y: scroll;
            max-height: 690px;

            .card-body {
                padding: 0;
            }
        }

        .modal-content {
            height: 690px;
        }

        fieldset#before,
        fieldset#after {
            border: 1px solid #c3c3c3;
            border-radius: 5px;
            padding: 15px;
            margin-right: 10px;

            legend {
                text-align: center;
                width: auto;
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .diag-list-gap {
            position: relative;
            height: auto;
            margin: auto 0;
            flex: 0 0 3.333333%;

            .diag-list-gap-img {
                display: flex;
                flex-direction: column;
                width: 70px;
                height: 70px;
                margin: 110px 5px 110px 0;
            }
        }

        .change-transfer-date-btn {
            min-width: 95px;
        }
    }

    #vehicle-csv-setting-modal {
        #vehicle-csv-setting-modal-csv-error {
            width: 100%;
            max-height: 150px;
            list-style-type: none;
            color: $danger;
            font-size: 0.9rem;
            overflow-y: scroll;
            border: 1px solid $gray-500;
        }

        #vehicle-csv-setting-modal-csv-submit-message {
            color: $green;
            font-size: 0.9rem;
        }
    }

    #inspection_repair-detail-modal {

        //修理履歴のスクロールを調整
        .detail-overflow {
            max-height: 55vh;
        }

        table.sticky-head-table {
            border-collapse: separate;
            border-spacing: 0;
        }

        thead#inspection_repair {
            top: -2px;
        }

        .sticky-footer {
            position: -webkit-sticky;
            background-color: $bss_color_logo_blue !important;
            color: $bss_color_background !important;
            bottom: 0;
            position: sticky;
            text-align: center;
        }

        .blank-footer {
            background-color: white !important;
            color: white !important;
            border-left-style: hidden;
            border-bottom-style: hidden;
        }
    }

    #daily_check-setting-modal {
        .modal-daily_check {
            max-width: inherit;
            position: relative;
            width: 80%;
        }

        .modal-body {
            max-height: 800px;
            height: 80vh;
            margin: 15px;
            overflow-y: auto;
        }

        .modal-content {
            width: 80%;
        }

        .daily_check-setting-modal-switch-row {
            label.input-label {
                min-height: 50px;
            }
        }

        .bottm-row {
            padding-top: 20px;
        }

        @media screen and (max-width: 575px) {
            .modal-daily_check {
                width: auto;
            }
        }
    }

    .vehiclelist-code,
    .vehiclelist-name {
        text-align: left;
    }

    .vehiclelist-number {
        text-align: right;
    }

    .vname {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    /** 車検証情報画面のカレンダー表示設定 */
    .vehicle-search-form-month {
        margin: 0;

        .month-picker {
            font-size: unset;
            height: unset;
            bottom: unset;
            left: unset;
        }
    }

    // 車両台帳リスト選択時の各ヘッダー部
    .vehicle-table {
        thead th {
            font-size: 16px;
        }

        .checkbox {
            width: 40px;
        }

        .th-company_name {
            width: 190px;
            max-width: 190px;
        }

        .th-section_name {
            width: 150px;
            max-width: 150px;
        }

        .th-vehicle_code {
            width: 120px;
            max-width: 120px;
        }

        .th-vehicle_name {
            width: 240px;
            max-width: 240px;
        }

        .th-certificate_vehicle_number {
            width: 150px;
            max-width: 150px;
        }

        .th-certificate_car_name {
            width: 150px;
            max-width: 150px;
        }

        .th-certificate_home_business {
            width: 60px;
            max-width: 60px;
        }

        .th-certificate_body_shape {
            width: 150px;
        }

        .th-mileage {
            width: 110px;
        }

        .th-last_drive_date {
            width: 110px;
        }

        .th-status {
            width: 80px;
        }

        .th-vehicle_door_name {
            width: 60px;
        }

        .th-oil_change_mileage {
            width: 80px;
        }

        .th-oil_change_date {
            width: 110px;
        }

        .th-element_change_mileage {
            width: 80px;
        }

        .th-element_change_date {
            width: 110px;
        }
        .th-tire_change_mileage {
          width: 80px;
        }
        .th-tire_change_date {
          width: 110px;
        }
        .th-battery_change_mileage {
          width: 80px;
        }
        .th-battery_change_date {
          width: 110px;
        }
        .th-tow_type {
            width: 80px;
        }

        .th-certificate_date {
            width: 110px;
        }

        .th-certificate_expired {
            width: 110px;
        }

        .th-certificate_weight {
            width: 80px;
        }

        .th-certificate_length {
            width: 80px;
        }

        .th-certificate_width {
            width: 80px;
        }

        .th-certificate_height {
            width: 80px;
        }

        .th-parking {
            width: 160px;
        }

        .th-parking_date {
            width: 110px;
        }

        .th-purchase_date {
            width: 110px;
        }

        .th-created_at {
            width: 120px;
        }

        .th-updated_at {
            width: 120px;
        }

        .th-vehicle_license {
            width: 55px;
            max-width: 55px;
        }

        tbody td {
            text-align: left;
        }

        td.td-mileage,
        td.td-last_drive_date {
            text-align: right;
        }
    }

    // 詳細：保険情報のPDF閲覧ボタン
    .ins-pdf-btn-image>* {
        padding: 5px;
        margin: 0 8px 0 0;
    }

    // switch幅調整
    .vehicle-switch {

        // ボタン幅調整
        .btn-group {
            .btn-default {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }

    // 給油モーダルの設定
    .vehicle-refuel-list-table {
        thead th {
            // padding: 0;
            font-size: 18px;
            vertical-align: middle;
            text-align: center;
            background-color: $bss_color_logo_blue;
            color: $bss_color_background;
            width: calc(100% / 13);
        }

        th.driver {
            width: 12%;
        }

        th.refuel-vehicle {
            width: 12%;
        }

        th.refuel-amount {
            width: 8%;
        }

        th.price {
            width: 8%;
        }

        th.odo-meter {
            width: 18%;
        }

        tbody td {
            font-size: 15px;
            height: 50px;
            vertical-align: middle;
            text-align: left;
            padding: 0 0 0 10px;

            &.error {
                background-color: $table-bg-color-pink;
            }
        }

        td.number,
        td.amount,
        td.refuel-amount,
        td.odo-meter,
        td.price {
            text-align: right;
            padding-right: 10px;
        }

        td.receipt-image {
            text-align: center;
        }

        td.menu {
            text-align: center;
            padding-left: 0;
        }

        .receipt-img {
            width: 50px;
            height: 50px;
            object-fit: cover;
        }
    }

    .table {
        .receipt-img {
            width: 50px;
            height: 50px;
            object-fit: cover;
        }
    }

    // 給油モーダルの設定
    .vehicle-refuel-table {
        thead th {
            // padding: 0;
            font-size: 18px;
            vertical-align: middle;
            text-align: center;
            background-color: $bss_color_logo_blue;
            color: $bss_color_background;
            width: calc(100% / 13);
        }

        th.company,
        th.section {
            width: 18%;
        }

        th.vehicle-code {
            width: 8%;
        }

        th.vehicle-name {
            width: 8%;
        }

        th.vehicle-door-name {
            width: 8%;
        }

        th.vehicle-number {
            width: 8%;
        }

        th.refuel-amount {
            width: 8%;
        }

        th.price {
            width: 12%;
        }

        th.status {
            width: 8%;
        }

        th.odo-meter {
            width: 15%;
        }

        tbody td {
            font-size: 15px;
            height: 50px;
            vertical-align: middle;
            text-align: left;
            padding: 0 0 0 10px;

            &.error {
                background-color: $table-bg-color-pink;
            }
        }

        td.number,
        td.amount,
        td.refuel-amount,
        td.odo-meter,
        td.price {
            text-align: right;
            padding-right: 10px;
        }

        td.menu {
            text-align: center;
            padding-left: 0;
        }

        td.status {
            text-align: right;
            padding-right: 10px;
        }

        .receipt-img {
            width: 50px;
            height: 50px;
            object-fit: cover;
        }
    }

    .modal-vehicle-search {
        margin-bottom: 15px;
    }

    .modal-vehicle-refuel-header {
        padding: 0;
    }

    .modal-vehicle-refuel-title {
        padding: 16px;
    }

    .modal-vehicle-refuel {
        max-width: inherit;
        position: relative;
        width: 96%;
    }

    .modal-vehicle-refuel-body {
        // height: auto;
        padding-top: 20px;
        overflow-y: scroll;
    }

    .modal-config-vehicle-setting {
        max-height: 300px;
        overflow-y: scroll;
    }

    .modal-config-vehicle-display {
        display: block;
        border-bottom: 0;
    }

    /* 車両設定：自家用選択を非表示 */
    .vehicle-config-content {
        .for-config-use_type {
            label:nth-child(2) {
                display: none;
            }
        }
    }

    .functions {
        overflow-x: auto;
        flex-wrap: nowrap;
        list-style-type: none;
        overflow-y: hidden;
        scrollbar-width: thin;
        /*for firefox*/
        scrollbar-color: #4c87c7 #d7d7d7;
        /*for firefox*/
    }

    .functions::-webkit-scrollbar {
        height: 10px;
    }

    .functions::-webkit-scrollbar-track {
        background-color: #d7d7d7;
        border-radius: 3px;
    }

    .functions::-webkit-scrollbar-thumb {
        background-color: #4c87c7;
        border-radius: 3px;
    }

    @media screen and (max-width: 575px) {
        .functions {
            margin: 0 0 5px 0 !important;

            .btn-app {
                margin: 0 0 0 10px !important;
            }
        }
    }

    .bootstrap-datetimepicker-widget {
        z-index: 2000;
    }

    #button-refuel-setting-save {
        margin-left: 25px;
    }

    #vehicle-refuel-image-modal {
        background-color: transparent;

        #recept-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    td#lease-file {
        text-align: center;
    }

    #vehicle-lease-detail-modal {
        .detail-overflow {
            max-height: 38vh;
        }

        table.sticky-head-table {
            border-collapse: separate;
            border-spacing: 0;
        }
    }

    td.td-company_name {
        width: 200px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    td.td-section_name {
        width: 200px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    td.td-section_name {
        width: 200px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    td.td-vehicle_name {
        width: 150px;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    td.td-vehicle_door_name {
        width: 150px;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    td.td-mileage {
        white-space: nowrap;
    }

    td.td-status {
        white-space: nowrap;
    }
}
