.allocation-page {

    .nav-link[data-widget="navbar-search"] {
        display: none;
    }
    .navbar-search-block {
        display: none;
    }

    .card-body {
        min-height: 200px;
    }
}
