.daily_check-page {
    .header-top {
        top:-1px;
    }
    // 虫眼鏡を消す
    .nav-link[data-widget="navbar-search"] {
        display: none;
    }
    .navbar-search-block {
        display: none;
    }
    .between {
        padding : 38px 10px 0px 10px;
    }
    .list-btn-container {
        min-width: 100px;
        display: flex;
        justify-content: space-evenly;
    }
    .align-right {
        text-align: right !important;
    }
    .align-left {
        text-align: left !important;
    }
    .align-center {
        text-align: center !important;
    }

    /* テーブル */
    table[role=list]#daily_check-list-table {
        padding:0px;

        th {
            padding: 4px 0px 2px 0px;
            font-size: 15px;
            vertical-align: middle;
            text-align: center;
        }
        td {
            padding: 2px 6px 2px 6px;
        }
        th.company-name {
            width: 10%
        }
        th.section-name {
            width: 10%
        }
        th.vehicle-name {
            width: 10%
        }
        th.user-name {
            width: 8%
        }
        th.resulted-datetime {
            width: 8%
        }
        th.resulted-mileage {
            width: 6%
        }
        th.result {
            width: 3%
        }
        th.ok-count {
            width: 3%
        }
        th.ng-count {
            width: 3%
        }
        th.skip-count {
            width: 5%
        }
        th.operation-manager {
            width: 10%
        }
        th.maintenancer {
            width: 10%
        }
        th.operatable {
            width: 3%
        }
        th.buttons {
            width: 11%
        }
    }
    /* モーダル */
    #daily_check-setting-modal {
        .modal-daily_check {
            max-width: inherit;
            position: relative;
            width: 70%;
        }
        .modal-body {
            max-height: 800px;
            height: 80vh;
            overflow-y: auto;

            label.btn[data-label="〇"],
            label.btn[data-label="✖"] {
                font-weight: normal;
                font-size: 1.15rem;
                padding: 0 0.5rem;
            }
            #daily-check-item-block {
                overflow-y: scroll;
                height: 280px;
            }
        }
        @media screen and (max-width: 575px) {
            .modal-daily_check {
                width: auto;
            }
        }
    }

}
