/**
  for shipping_information-page
 */
.shipping_information-page {
    table#shippinginformationlist{
        .td-slip_number, .td-equipment_id,
        .td-holding_code, .td-company_code, .td-section_code {
            text-align: right;
        }

        .th-slip_number{
            width: 100px;
            max-width: 100px;
        }
        .th-holding_code, .th-company_code, .th-section_code {
            width: 120px;
            max-width: 120px;
        }
        .th-holding_name, .th-company_name, .th-section_name {
            width: 150px;
            max-width: 150px;
        }
        .th-ship_date, .th-estimated_arrival_date, .th-return_date {
            width: 90px;
            max-width: 90px;
        }
        .th-model_information, .th-shipping_category{
            width: 80px;
            max-width: 80px;
        }
        .th-equipment_id {
            width: 150px;
            max-width: 150px;
        }
    }
    #shipping_information-setting-modal {
        .modal-body {
            overflow-y: visible;
            max-height: 600px;
        }
    }
    .visibility-hidden {
        visibility: hidden;
    }

    .onoff_type_switch .btn {
        min-width: 50px;
    }
}
