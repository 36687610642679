// attendance
.attendance-page {
    .attendance-user-information {
        span {
            margin-right: 15px;
            font-size: 1.5rem;
            display: inline-block;
            line-height: 2rem;
        }
    }
    .chart-period {
        font-size: 20px;
    }
    .edit-by-cloud {
        color: $font-color-blue;
    }
    .print-area {
        .detail-overflow {
            max-height: none;
        }
    }
    .attendance-table {
        td{
            text-align: center;
        }
    }
    .align-right {
        text-align: right !important;
    }
    .align-left {
        text-align: left !important;
    }
    .align-center {
        text-align: center !important;
    }
    //出退勤一覧　出退勤部分のスクロールを調整
    .detail-overflow {
        max-height: 75vh;
    }
    @media screen and (max-width: 996px) {
        .detail-overflow {
            max-height: 65vh;
        }
    }
    .header-top{
        top:-1px;
    }

    .sticky-footer {
        td{
            position: -webkit-sticky;
            background-color: $bss_color_logo_blue !important;
            color: $bss_color_background  !important;
            bottom: 0;
            position: sticky;
        }
    }

    .sticky-footer-th1 {
        position: -webkit-sticky;
        background-color: $bss_color_logo_blue !important;
        color: $bss_color_background  !important;
        bottom: 0;
        position: sticky;
        text-align: center;
    }

    .sticky-footer-th2 {
        position: -webkit-sticky;
        background-color: $bss_color_logo_blue !important;
        color: $bss_color_background  !important;
        bottom: 37px;
        position: sticky;
        /* tbody内のセルより手前に表示する */
        z-index: 1;
        text-align: center;
    }
    .margin-right {
        margin: 0 0 0 auto;
    }

    //出退勤一覧等の表示幅を設定
    .attendance-width-aa{
        width: 4%;
    }
    .attendance-width-a{
        width: 5%;
    }
    .attendance-width-b{
        width: 6%;
    }
    .attendance-width-c{
        width: 7%;
    }
    .attendance-width-d{
        width: 8%;
    }
    .attendance-width-e{
        width: 9%;
    }
    .attendance-width-f{
        width: 10%;
    }
    .attendance-width-g{
        width: 16%;
    }
    .attendance-width-l{
        width: 18%;
    }
    .weekly-total {
        height: 37px;
        background-color: #00335f !important;
        color: #FFFFFF !important;
    }
    
    .attendance-day-calendar-text-position {
        text-align: center;
    }

    .modal-config-employ-setting{
        max-height: 300px;
        overflow-y: scroll;
    }
    .modal-config-employ-display{
        display: block;
        border-bottom: 0px;
    }

    #attendance-record-modal {
        .event-input-button {
            position: absolute;
            top: -30px;
        }
    }

    #attendance-setting-modal {

        .attendance-event-inputs .event-input-form {
            height: 162px;
            overflow-y: scroll;
        }
        .attendance-event-inputs .event-input-form-wrapper {
            position: relative;
            height: 162px;
        }
        #attendance-setting-modal-reason {
            min-height: 50px;
        }
    }

    #attendance-schedule-modal {
        #weekday-setup-form {
            #weekday-setup-form-title {
                background-color: $white;
                margin-left: 20px;
                padding: 0 8px;
                font-weight: normal;
            }
            #weekday-setup-form-contents {
                border: 1px solid $gray-400;
                border-radius: 8px;
                margin-top: -20px;
                padding: 20px 0 10px 0;
            }
        }
        #attendance-schedule-modal-daily-schedule {
            .schedule-work-type {
                width: 100px;
            }
            .schedule-time {
                width: 120px;
            }
        }
        #attendance-schedule-target-users {
            .user-name {
                border-radius: 8px;
                background-color: $gray-200;
                display: inline-block;
                padding: 5px 8px;
                margin: 2px;
            }
        }
        .attendance-schedule-table {
            width: 100%;
            .attendance-schedule-table-header {
                text-align: center;
                background-color: $bss_color_logo_blue;
                color: $white;
                text-align: center;
                th  {
                    padding: 6px 8px;
                    border: 1px solid #dee2e6;
                }
            }
            .attendance-schedule-daily-item:nth-of-type(odd) {
                background-color: rgba(0, 0, 0, 0.05);
            }
            .attendance-schedule-daily-item-td {
                border: 1px solid #dee2e6;
            }
        }
    }

    #attendance-config-modal {
        input.closing_day {
            display: inline-block;
            width: 80px;
        }
        .closing_day-attach-text-ja::before {
            content: '\6BCE\6708'
        }
        .closing_day-attach-text-ja::after {
            content: '\65E5';
        }
        .closing_day-attach-text-en::before {
            content: ''
        }
        .closing_day-attach-text-en::after {
            content: 'th of every month';
        }

        .setting-tabpanel {
            height: 400px;
        }
        .print-column-chip {
            margin:2px 3px;
            padding:3px 6px;
        }
        .btn-disable {
            border-color: $gray-500;
        }
        
        .weekly-display-annotation {
            font-size: 0.7rem;
            font-weight: 100;
            color: $gray-700;
            margin-block-start: 0.5em;
            margin-block-end: 0.5em;
            margin: 0;
            &:before {
                content: "※ ";
            }
        }
    }

    #attendance-day-modal {
        .modal-header {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .modal-body-detail {
            margin-top: 20px;
        }
        .list-area {
            margin-top: 20px;
        }
    }

    .holiday-rest {
        font-size: 1.5rem;
        padding-right: 25px;
        position:relative;
        top: 28px;
    }
    
    .header-top{
        top:-1px;
    }
    .sticky-top-zindex1 {
        position:sticky;
        top:0;
        z-index:1;
    }

    .attendance-page .modal-attendance {
        max-width: inherit;
        position: relative;
        width: 90%;
    }

    table th.attendance-day-sort-column {
        cursor: pointer;
        .desc-icon, .asc-icon {
            display: none;
        }
        &.asc:hover {
            .asc-icon { display: inline-block; }
        }
        &.desc:hover {
            .desc-icon { display: inline-block; }
        }
        &.asc.active {
            .asc-icon { display: inline-block; }
        }
        &.desc.active {
            .desc-icon { display: inline-block; }
        }
        &.asc.active:hover {
            .desc-icon { display: inline-block; }
            .asc-icon { display: none; }
        }
        &.desc.active:hover {
            .asc-icon { display: inline-block; }
            .desc-icon { display: none; }
        }
    }

    .sort-disp {
        display: inline-block !important;
    }
    
    .sort-no-disp {
        display: none !important;
    }
}
