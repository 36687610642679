.device-page {
    @media screen and (min-width: 768px) {
      .col-md-1_25 {
        -webkit-flex: 0 0 10.4%;
        -ms-flex: 0 0 10.4%;
        flex: 0 0 10.4%;
        max-width: 10.4%;
        padding: 0 2px;
      }
    }
    // .col-md-1_25 .datetimepicker-input {
    //   font-size: 0.55em;
    // }
    .device-content {
      margin-right: 20px;
      margin-bottom: 10px;
      .period {
        font-size: 14px;
        height: 20px;
        text-align: center;
      }
    }
    .modal-device {
      max-width: inherit;
      position: relative;
      width: 68%;
    }
    #device-setting-modal {
      .modal-body {
        overflow-y: scroll;
        max-height: 860px;
        .card-body {
          padding: 0px;
        }
      }
      .modal-body.transfered {
        input{
          pointer-events : none;
        }
      }
      .modal-content {
        height: 850px;
      }
      #base-part-submit,
      #certificate-part-submit,
      #insurance-part-submit {
        button[data-confirm-step] {
          color: #fff;
          background-color: #4c87c7;
        }
      }
      .image-preview {
          width: 270px;
          height: 170px;
          margin-top: 33px;
          img {
            width: 100%;
            object-fit: cover
          }
      }
      .image-setting {
        .input-group-btn {
            width: 150px;
            .btn {
                width: 100%;
            }
        }
      }
      .btn-group-toggle {
        .px-3 {
          min-width: 71px;
        }
      }
      hr {
        margin-top: 0.2rem;
      }
      .titled-box-frame {
        position: relative;
        margin-top: 1em;
        padding: 0.8em 0 0.6em 1em;
        border: 1px solid #c3c3c3;
        width: 95%;
        border-radius: 4px;
      }
      .titled-box-title {
        position: absolute;
        padding: 0 0.5em;
        left: 10px;
        top: -13px;
        font-weight: bold;
        background: #fff;
      }
      .titled-box-frame dd,
      .titled-box-frame dt {
            width: 100% !important;
      }
      .flex-box {
        display: flex;
      }
      .box1 {
        display: none;
      }
      .evident {
        display: block;
      }
    }
    .modal-device {
      max-width: inherit;
      position: relative;
      width: 68%;
    }
    table#devicelist {
      span {
        border-radius: 15px;
        line-height: 12px;
        font-size: 14px;
        // height: 20px;
        min-width: 20px;
        // float: right;
      }
      .th-holding_name {
        width: 200px;
        max-width: 200px;
      }
      .th-company_name {
        width: 200px;
        max-width: 200px;
      }
      .th-section_name {
          width: 200px;
          max-width: 200px;
      }
      .th-device_name {
        width: 150px;
        max-width: 150px;
      }
      .th-alc_type {
        width: 140px;
        max-width: 140px;
      }
      .th-alert_cnt {
        width: 160px;
        max-width: 160px;
      }
      .th-used_sensor_cnt {
        width: 180px;
        max-width: 180px;
      }
      .th-used_all_cnt {
        width: 180px;
        max-width: 180px;
      }
      .th-equipment_id {
        width: 180px;
        max-width: 180px;
      }
      .th-sensor_first_use_date {
        width: 180px;
        max-width: 180px;
      }
      .th-maintenance_date {
        width: 180px;
        max-width: 180px;
      }
      .th-status {
        width: 160px;
        max-width: 160px;
      }
      .th-dest_section_name {
        width: 200px;
        max-width: 200px;
      }
      .th-dest_section_address {
        width: 400px;
        max-width: 400px;
      }
      .th-change {
        width: 100px;
        max-width: 100px;
      }
      .th-menu {
        width: 100px;
        max-width: 100px;
      }
      .th-created_at {
        width: 160px;
        max-width: 160px;
      }
      .th-updated_at {
        width: 160px;
        max-width: 160px;
      }
      .th-is_tracked {
        width: 100px;
        max-width: 100px;
      }
      tbody td {
        text-align: left;
      }
      .td-alert_cnt, .td-used_sensor_cnt, .td-used_all_cnt {
        text-align: right;
      }
      .td-sensor_first_use_date, .td-maintenance_date {
        text-align: center;
      }
    }
    // .vname {
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   white-space: nowrap;
    // }
    // アラート用の位置
    .alert {
      padding: 0;
      position: relative;
      left: 20px;
      // ボタン幅調整
      .btn-group {
        .btn-default {
          width: 80px;
        }
      }
    }
}
