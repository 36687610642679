.inspection-page {
    .card-footer{
        background-color: white;
        margin-top: 201px;
        margin-bottom: 23px;
        padding: 0.75rem 1.25rem;
    }
    .card-footer-list{
        background-color: white;
        margin-top: 23px;
        margin-bottom: 23px;
        padding: 0.75rem 1.25rem;
    }
    .card-footer-sub{
        background-color: white;
        margin-top: 34px;
        margin-bottom: 23px;
        padding: 0.75rem 1.25rem;
    }

    .schedule-list-row{
        display:flex;
        flex-wrap: nowrap;
        height: 100%;
    }
    .schedule-list-header{
        width: 100%;
        padding: 7px;
        border: 1px solid;
        text-align: center;
        font-size: 1em;
        font-weight: 800;
        background-color: #0055a0;
        color: white;
    }
    .schedule-list{
        padding-bottom: 15px;
        border: 1px solid #c1c6ca;

        .list-font{
            font-weight: 800;
            margin-left: 7px;
            margin-top: 20px;
        }

        .plan {
            span.type_text,img {
                display:none;
            }
            span.expire-date {
                display:inline;
                color:red;
            }
        }
    }
    .inspection-list-gap{
        position: relative;
        height: auto;
        margin: auto 0;
        flex: 0 0 3.333333%;
        .inspection-list-gap-img{
            display:flex;
            flex-direction: column;
            margin: 93% 0;
            width: 48px;
            height: 48px;
        }
    }
    .inspection-calendar {
        width: 100%;
        height: 100%;
        margin-bottom: 0 !important;
        thead tr th {
            font-size: 1em;
            width: calc(100% / 7);
            border: 1px solid #c1c6ca;
        }
        tbody tr td {
            vertical-align: top;
            border: 1px solid #c1c6ca;
            p {
                font-size: 2em;
            }
            @media screen and (max-width: 767px) {
                p {
                    font-size: 1.5rem;
                }
            }
        }
        .plan {
            text-align: left;
        }
    }

    .th-display_company_name { min-width : 10% }
    .th-display_section_name { min-width : 10% }
    .th-display_vehicle_name { min-width : 10% }
    .th-display_expected_date_start { min-width : 15% }
    .th-display_expected_date_end { min-width : 15% }
    .th-display_completed_date_start { min-width : 15% }
    .th-display_completed_date_end { min-width : 15% }
    .th-display_inspection_type_name_short { min-width : 10% }
    .th-manager_name { min-width : 10% }
    .th-display_own_or_other { min-width : 5% }
    .th-display_request_destination { min-width : 15% }
    .th-display_mileage { min-width : 5% }
    .th-display_note { min-width : 5% }
    .th-created_at { min-width : 10% }
    .th-updated_at { min-width : 10% }

    .modal-body {
        overflow-y: hidden;
    }
    .modal-body-list {
        overflow-y: visible;
    }
    .modal-body-schedule{
        overflow-y: hidden;
    }
    .datepicker table th,
    .datepicker table td{
        font-size: 1.0em;
    }
    .plan {
        position: relative;
        border-radius: 4px;
        background: #e1af1e;
        color: #fff;
        font-size: 1rem;
        width: 100%;
        padding: 6px 6px;
        margin-top: 1px;
        cursor: pointer;
        text-align: center;
        p.vehicle_name {
            font-size: 0.9rem;
            line-height: 1.2rem;
            margin: 2px;
        }
        span.type_text {
            float: left;
            background-color: #fff;
            color: #F00;
            font-size: 0.75rem;
            font-weight: bold;
            padding: 0px 1px;
            margin: 1px 3px 0 0;
            border: 1px solid #F00;
        }
    }
    @media screen and (max-width: 767px) {
        .plan {
            span.type_text {
                padding: 0 0 0 2px;
                line-height: 1rem;
                width: 1rem;
                height: 1rem;
                overflow: hidden;
            }
        }
    }
    .spec-close {
        background-color: $gray-600;
        img {
            float: right;
            right: 1px;
            top: 1px;
            width: 20px;
            margin: 0 0 2px 2px;
        }
    }
    .text-gray2 {
        color:rgb(0, 0, 0);
    }
    .col-form-label {
        font-size: 0.95em;
    }
    .invalid-feedback {
        margin: 0;
    }
    .inspection-list th{
        text-align: center;
    }
    .inspection-list thead tr,
    .inspection-list tbody tr td {
        font-size: 0.9em;
        border: 1px solid #c1c6ca;
        text-align: left;
    }
    .datepicker table th,
    .datepicker table td{
        font-size: 1.0em;
    }
    textarea.form-control {
        min-height: 160px;
        height: 160px;
    }
    #inspection-setting-modal-note {
        min-height: 85px !important;
        height: 85px !important;
    }
    .bg-lightred {
        background: #fff2f8 !important;
    }
    .bg-lightsilver {
        background: $gray-200 !important;
    }
    .list-table-tr-color{
        background: white !important;
    }

    //修理履歴のスクロールを調整
    .detail-overflow {
        max-height: 50vh;
    }
    thead#inspection_repair {
        top: -2px;
    }
    .sticky-footer {
        position: -webkit-sticky;
        background-color: $bss_color_logo_blue !important;
        color: $bss_color_background  !important;
        bottom: 0;
        position: sticky;
        text-align: center;
    }
    .blank-footer {
        background-color: white !important;
        color: white !important;
        border-left-style:hidden;
        border-bottom-style:hidden;
    }

    .modal-min{
        min-height: 100px;
    }

    .icon-add-container {
        div:not(.dropdown-menu) {
            display: inline-block;
        }
    }

    //印刷ボタン運行管理者
    .dropdown-menu {
        margin-right: 93px;
    }

    /** 今月予定件数・来月予定件数表示設定
    *   .count
    *   → 点呼割合の全体表示設定
    *     点呼執行者 or 点呼補助者に応じて各設定を実施 (&.enforcer-rate, &.assistant-rate)
    *   img
    *   → 点呼割合に使用されている枠の画像設定
    *     画像上に文字を表示させるために、position:absoluteを指定
    *   .rate-value
    *   → 点呼割合に表示される値の設定
    *     画像上に文字を表示させるために、position:relativeを指定
    *     top, leftで表示位置の設定を行い、fontsizeを考慮した位置設定をtransformで実施
    */
    .inspection-count-block {
        .count {
            display: inline-block;
            width: 124px;
            height:124px;
            background-repeat: no-repeat;
            background-size: 124px 124px;
            background-position: 0 0;
            &.this-month-not-completed {
                background-image: url("/assets/img/inspection/inspection-this-month-not-completed-count.png");
            }
            &.this-month-completed {
                background-image: url("/assets/img/inspection/inspection-this-month-completed-count.png");
            }
            &.next-month-not-completed {
                background-image: url("/assets/img/inspection/inspection-next-month-not-completed-count.png");
            }
            .count-value {
                text-align: center;
                font-size: 3rem;
                padding-top: 2rem;
            }
        }
        @media screen and (max-width: 767px) {
            .count {
                width: 100px;
                height:100px;
                background-size: 100px 100px;
                .count-value {
                    text-align: center;
                    font-size: 2rem;
                    padding-top: 1.8rem;
                }
            }
        }
    }

    .modal-inspection {
        max-width: inherit;
        position: relative;
        width: 80%;
    }

    #add-button-container {
        .icon-button-inputs {
            margin-top: 55px;
        }
    }

    #search-form-csv_download {
        margin: 0px;
    }
    #search-form-csv_upload {
        margin: 0px;
    }

    #inspection_repair-setting {
        padding-left: 0;

        .cards-container {
            max-height: 240px;
        }

        .cards-header {
            max-height: 35px;
        }

        &-hr-up {
            height: 6px;
            width: 100%;
            margin-top: -6px;
            margin-bottom: -14px
        }
        &-hr-down {
            height: 6px;
            width: 100%;
            margin-top: -18px;
            margin-bottom:-4px;
        }

        #option-holder {
            display: none;
        }
    }

    #inspection-chart-modal {
        .modal-inspection_repair {
            max-width: inherit;
            position: relative;
            width: 80%;
        }
        @media screen and (max-width: 575px) {
            .modal-inspection_repair {
                width: auto;
            }
        }
    }
}
/*
   修理履歴登録モーダル設定
   車両台帳でも使用するためglobal設定する
   id重複注意
*/
#inspection_repair-detail-modal {
    .modal-inspection_repair {
        max-width: inherit;
        position: relative;
        width: 80%;
    }
    .modal-body {
        height: 80vh;
    }
    @media screen and (max-width: 575px) {
        .modal-inspection_repair {
            width: auto;
        }
    }
}
/* CSV アップロードモーダル */
#inspection-csv-setting-modal {
    #inspection-csv-setting-modal-csv-error {
        width: 100%;
        max-height: 150px;
        list-style-type: none;
        color: $danger;
        font-size: 0.9rem;
        overflow-y: scroll;
        border: 1px solid $gray-500;
    }

    #inspection-csv-setting-modal-csv-submit-message {
        color: $green;
        font-size: 0.9rem;
    }
  }
