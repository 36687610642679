.order-page {
    .modal-order {
        min-width: 90%;

        #consignor-block {
            border: 1px solid #D5D5D5;
            background: #f1f1f1;
            position:relative;
            width: 100%;
            height: auto;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;

            &::after, &::before {
                border: solid transparent;
                content:'';
                height:0;
                width:0;
                pointer-events:none;
                position:absolute;
                right:100%;
                bottom:10%;
            }
            &::after{
                border-color: rgba(255, 255, 255, 0);
                border-top-width:6px;
                border-bottom-width:6px;
                border-left-width:8px;
                border-right-width:8px;
                margin-top: -6px;
                border-right-color:#f1f1f1;
            }
            &::before{
                border-color: rgba(213, 213, 213, 0);
                border-top-width:7px;
                border-bottom-width:7px;
                border-left-width:9px;
                border-right-width:9px;
                margin-top: -7px;
                margin-right: 1px;
                border-right-color: #D5D5D5;
            }
            dl {
                display: flex;
                flex-wrap: wrap;
                font-size: 0.8rem;
                margin: 0;
                dt {
                    width: 40%;
                    font-weight: 600;
                }
                dd {
                    width: 60%;
                    margin: 0;
                    padding: 0 10px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
        #user-expect-block,
        #vehicle-expect-block {
            border: 1px solid #D5D5D5;
            background: #f1f1f1;
            position: relative;
            width: 100%;
            height: auto;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            table {
                min-width: 70%;
            }
            table caption {
                padding: 0 0 0 2px;
                font-size: 0.8rem;
                font-weight: 600;
                caption-side: top;
                color: unset;
            }
            table tbody th,
            table tbody td {
                font-size: 0.8rem;
                padding: 0 5px 0 2px;
            }
        }
    }
    @media (max-width: 767px) {
        .modal-order {
            min-width: 85%;
            #consignor-block {
                margin-bottom: 15px;
                position: unset;
                &::after, &::before{
                    border: unset;
                    content:'';
                    height: unset;
                    width: unset;
                    right: unset;
                    bottom: unset;
                }
                &::after{
                    border-color: unset;
                }
                &::before{
                    border-color: unset;
                }
            }
            #user-expect-block,
            #vehicle-expect-block {
                table {
                    min-width: 85%;
                }
            }
        }
    }
    @media (max-width: 1180px) {
        .modal-order {
            #user-expect-block,
            #vehicle-expect-block {
                table {
                    min-width: 85%;
                }
            }
        }
    }
    @media (min-width: 1300px) {
        .modal-order {
            min-width: 85%;
            #consignor-block {
                dl {
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 0.8rem;
                    margin: 0;
                    dt {
                        width: 35%;
                        font-weight: 600;
                    }

                    dd {
                        width: 65%;
                        margin: 0;
                        padding: 0 10px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
    @media (min-width: 1500px) {
        .modal-order {
            min-width: 70%;
        }
    }
    @media (min-width: 1700px) {
        .modal-order {
            min-width: 55%;
        }
    }
    #drawer-toggle {
        width: 5%;
        #drawer-open {
            padding: 2px;
            line-height: 1.5rem;
            writing-mode: vertical-rl;
            border-right: 1px solid #ddd;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            border-radius: 5px;
            background: #f8f9fa;
        }
        #drawer-content {
            height: 650px;
            overflow: auto;
            padding: 5px 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            background: #f8f9fa;

            h5#drawer-close {
                cursor: pointer;

                &::before {
                    content: "\f100";
                    font-family: "FontAwesome";
                    color: #4c87c7;
                    margin: 0 5px 0 0;
                }
            }
            button.already-sort {
                cursor: unset !important;
                &:hover {
                    background-color: #4c87c7;
                }
            }
            #external-draggable {
                div {
                    position: relative;
                    font-size: 0.85rem;
                    border-radius: 5px;
                    background: var(--fc-event-bg-color);
                    margin: 0 0 5px 0;
                    padding: 2px 20px 2px 5px;
                    color: var(--fc-event-text-color);
                    line-height: 1.2rem;

                    &::after {
                        position: absolute;
                        display: block;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto 5px;
                        content: "\f0da";
                        font-family: "Font Awesome 5 Free","Font Awesome 5 Brands";
                        font-weight: bold;
                        color: var(--fc-event-text-color);
                        font-size: 1rem;
                        height: 1rem;
                    }
                }
                div.not-allocate {
                    background: var(--red) !important;
                }
                div.external-item {
                    cursor: pointer;
                }
                div.external-draggable-item {
                    cursor: move;
                }
            }
        }
    }
    .summary-block {
        .rate {
            display: inline-block;
            width: 124px;
            height:124px;
            background-repeat: no-repeat;
            background-size: 124px 124px;
            background-position: 0 0;
            &.total-count {
                background-image: url("/assets/img/order/total_count.png");
            }
            &.user-count {
                background-image: url("/assets/img/order/user_count.png");
            }
            &.vehicle-count {
                background-image: url("/assets/img/order/vehicle_count.png");
            }
            .summary-value {
                text-align: center;
                font-size: 3rem;
                padding-top: 2rem;
            }
        }
        @media screen and (max-width: 767px) {
            .rate {
                width: 100px;
                height:100px;
                background-size: 100px 100px;
                .summary-value {
                    text-align: center;
                    font-size: 2rem;
                    padding-top: 1.8rem;
                }
            }
        }
    }
    #calendar-block {
        width: 95%;
        .not-allocate {
            background: var(--red);
            border: solid 1px var(--red);
        }
    }
}
.popover-order-thumb {
    width: 100px;
}
