.user-page {
    .user-content {
        margin-right: 20px;
        margin-bottom: 10px;
        .business-card {
            width: 325px;
            padding: 1rem 0rem 1rem 0.9rem;
            flex-direction:inherit;
            position: relative;
            margin-bottom: auto;
            .img {
                padding-right: 0px;
                padding-left: 0px;
                padding-top: 3px;
            }
            .user_license_image {
                display: flex;
                position: absolute;
                top: 100px;
                left: 270px;
                width: 45px;
                height: 45px;
                z-index: 1;
            }
            .info {
                margin-bottom: auto;
            }
            dd {
                height: 22px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: auto;
            }
        }

        // 退職時
        .retirement-card {
            background-color: $gray-400;
            width: 325px;
            padding: 1rem 0rem 1rem 0.9rem;
            flex-direction:inherit;
            margin-bottom: auto;
            .img {
                padding-right: 0px;
                padding-left: 0px;
                padding-top: 3px;
            }
            .user_license_image {
                display: flex;
                position: absolute;
                top: 100px;
                left: 270px;
                width: 45px;
                height: 40px;
                z-index: 1;
            }
            .info {
                margin-bottom: auto;
            }
            dd {
                height: 22px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: auto;
            }
        }
        .business-card:hover {
            background-color: #ffffe3 !important;
            color: #000000 !important;
        }
        .profile-user-img {
            border: 3px solid #adb5bd;
            margin: 0 auto;
            object-fit: cover;
            object-position: 50% 0;
            width: 100px;
            height: 100px;
        }

        // 期間
        .user_period {
            font-size: 0.73rem;
            bottom: -22px;
            text-align: center;
        }

    }
    .modal-user {
        max-width: inherit;
        position: relative;
        width: 80%;
    }
    @media screen and (max-width: 575px) {
        .modal-user {
            width: unset;
        }
    }
    #user-detail-modal {
        .modal-body {
            max-height: 900px;
            #accident, #violation, #guidance {
                width: 100%;
                overflow-x: auto;
                table {
                    th {
                        white-space: nowrap;
                        text-align: center;
                    }
                    td {
                        white-space: nowrap;
                        text-align: center;
                    }
                    td.ellipsis {
                        max-width: 130px;
                        p {
                            height: 22px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            margin-bottom: auto;
                            display: inline-block;
                            width: 100%;
                        }
                    }
                }
            }
        }
        #blood_pressure {
            overflow-y: scroll;
        }
        .modal-content {
            height: 90vh;
        }
        #content {
            height: 100%;
            .tab-pane {
                min-height: 200px;
            }
            #detail-tabs {
                div.nav-tabs {
                    flex-direction: column;
                    border-left: 1px solid #dee2e6;
                    border-bottom: none;
                }
            }
        }
        textarea {
            resize: none;
            background-color: white;
        }
        textarea::placeholder{
            color: transparent;
        }
        @media screen and (max-width: 1199px) {
            #content {
                .reverse {
                    display: flex;
                    flex-direction: column-reverse;
                    .tab-content {
                        margin-top: 20px;
                    }
                }
                #detail-tabs {
                    padding: 0 !important;
                    div.nav-tabs {
                        flex-direction: unset;
                        border-bottom: 1px solid #dee2e6;
                    }
                }
            }
        }
        #left-side {
            min-height: 100%;
            margin-bottom: 20px;
            border-right: 1px solid #dee2e6;
        }
        @media screen and (max-width: 1199px) {
            #left-side {
                min-height: unset;
                border-right: none;
            }
        }
        #right-side {
                height: 100%;
        }
        #basic.card{
            margin-right: 9px;
            .row.content {
                margin: 10px;
            }
            .img {
                margin-top: auto;
                margin-bottom: auto;
                margin-right: auto;
            }
            .info {
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        .profile-user-img,
        .base_profile_image-preview.image-preview img {
            border: 3px solid #adb5bd;
            margin: 0 auto;
            padding: 3px;
            width: 110px;
            height: 110px;
            object-fit: cover;
            object-position: 50% 0;
            border-radius: 50%;
        }
        .base_profile_image-preview.image-preview {
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }
        fieldset#personal, fieldset#belong, fieldset#login, fieldset#base_remarks {
            box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
            margin-bottom: 1rem;
            border-radius: 4px;
            padding: 5px;
            margin-right: 9px;
            position: relative;
            legend {
                width: auto;
                background-color: #fff;
                font-size: 14px;
                font-weight: bold;
                margin-left: 5px;
                margin-bottom: 0px;
                padding-left: 5px;
                padding-right: 5px;
            }
            .info {
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 2px
            }
            dd {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: auto;
                display: inline-block;
                width: 20ch;
            }
            .stamp {
                position: relative;
                top: 80px;
            }
            .stamp-img,
            .base_personal_stamp-preview.image-preview img {
                border: 1px solid #adb5bd;
                padding: 3px;
                width: 80px;
                height: 80px;
                object-fit: cover;
                float: right;
                border-radius: 50%;
            }
            .base_personal_stamp-preview.image-preview {
                width: 80px;
                height: 80px;
                float: right;
                padding: 3px;
                border-radius: 50%;
            }
            .license_file_btn {
                position: absolute;
                bottom: 10px;
                right: 15px;
            }
        }

        fieldset#health_remarks {
            border: 1px solid #c3c3c3;
            border-radius: 4px;
            padding: 5px 5px 10px 5px;
            margin-right: 9px;
            margin-bottom: 13px;
            width: 100%;

            legend {
                width: auto;
                font-size: 14px;
                font-weight: bold;
                margin-left: 5px;
                margin-bottom: 0;
                padding-left: 5px;
                padding-right: 5px;
            }

            .info {
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 2px;
            }

            dd {
              height: 25px;
              margin-bottom: auto;
                padding-left: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
        }

        dt {
            margin-top: 1px;
            margin-bottom: 1px;
        }
        dd {
            margin-bottom: 0px;
        }
        th.error, td.error {
            font-weight: bold;
            color: red;
        }
        .nav-tabs {
            line-height: 1.2rem;
            .nav-link {
                border-color: #dee2e6 #dee2e6 #dee2e6 transparent;
                &.active {
                    background-color: $blue;
                    color: $white;
                }
            }
        }

        #vert-tabs-right-profile {
            tr.sticky-top {
                background-color: #f2f2f2;
            }
            .sticky-head-table {
                border-collapse: separate;
                border-spacing: 0;
            }
        }

        #vert-tabs-right-accident, #vert-tabs-right-violation, #vert-tabs-right-guidance {
            .sticky-top {
                background-color: $white;
            }
            .sticky-head-table {
                border-collapse: separate;
                border-spacing: 0;
            }
        }

        #vert-tabs-right-insurance {
            td:first-child {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 300px;
            }
            @media screen and (min-width: 1200px) and (max-width: 1600px) {
                td:first-child {
                    max-width: 200px;
                }
            }
        }
        .paid-holiday-expiration-months {
            font-size: 0.75rem;
            &::before {
                content: "(";
            }
            &::after {
                content: ")";
            }
        }

    }
    #user-setting-modal {
        .modal-body:not(.files-dispose-modal .modal-body) {
            max-height: 900px;
            .card-body.content {
                padding: 0px;
            }
            button[data-toggle="collapse"] {
                width: 100%;
                text-align: start;
                background-color: #0055a0;
                padding: 0.75rem 1.25rem;
                position: relative;
            }
            button[data-toggle="collapse"][aria-expanded="false"]:after {
                display: inline-block;
                content: "\f067";
                font-family: "Font Awesome 5 Free", "Font Awesome 5 Brands";
                font-size: 1rem;
                font-weight: bold;
                vertical-align: middle;
                position: absolute;
                right: 1.25rem;
            }

            button[data-toggle="collapse"][aria-expanded="true"]:after {
                display: inline-block;
                content: "\f068";
                font-family: "Font Awesome 5 Free", "Font Awesome 5 Brands";
                font-size: 1rem;
                font-weight: bold;
                vertical-align: middle;
                position: absolute;
                right: 1.25rem;
            }
            #personal-file .date-picker-inputs {
                position: relative;
            }
        }

        // 退職時、詳細画面の全ての入力を制御
        .transfered {
            input{
                pointer-events : none;
            }
        }
        .retire-forms {
            .master-switch label {
                font-size: 0.8rem;
                padding-right: 0.5rem !important;
                padding-left: 0.5rem !important;
            }
        }
        .modal-content:not(.files-dispose-modal .modal-content) {
            height: 900px;
        }
        .image-preview {
            width: 150px;
            height: 150px;
        }
        .stamp-preview {
            width: 90px;
            height: 90px;
        }
        input[type="checkbox"], input[type="radio"] {
            margin-right: 4px;
        }
        .form-inline {
            div {
                margin-right: 2px;
            }
            input:not([type="checkbox"]) {
                width: 100%;
            }
            input[name="address"] {
                width: 400px !important;
            }
            .date-picker-inputs {
                width: 100%;
            }
            .form-control:not(.prefecture):not(.zipcode):not(.municipality) {
                width: 100%;
            }
            .form-control.prefecture {
                margin-left: 4px;
            }
            .date-picker {
                width: 100%;
            }
        }
        .bs-stepper .step-trigger.disabled, .bs-stepper .step-trigger:disabled {
            opacity: 1;
        }
        .form-group {
            margin-bottom: 10px;

        }
        .form-check:not(.display-none) {
            display: inline-block;
            margin-right: 10px;
        }
        #base-part-submit,
        #belong-part-submit,
        #occupation-part-submit,
        #license-part-submit {
            button[data-confirm-step] {
                color: #fff;
                background-color: #4c87c7;
            }
        }
        .zipcode-inputs {
            #user-setting-modal-zip {
                display: inline-block;
            }
        }
        .address-inputs {
            #user-setting-modal--prefecture {
                max-width: 150px;
            }
        }
    }
    #user-csv-setting-modal {
        #user-csv-setting-modal-csv-error {
            width: 100%;
            max-height: 150px;
            list-style-type: none;
            color: $danger;
            font-size: 0.9rem;
            overflow-y: scroll;
            border: 1px solid $gray-500;
        }
        #user-csv-setting-modal-csv-submit-message {
            color: $green;
            font-size: 0.9rem;
        }
    }
    #guidance-setting-modal {
        .modal-guidance {
            max-width:1000px;
        }
    }
    table#userlist {
        .checkbox {
            width: 40px;
        }
        .th-company_name {
            width: 200px;
            max-width: 200px;
        }
        .th-section_name {
            width: 200px;
            max-width: 200px;
        }
        .th-user_code {
            width: 130px;
        }
        .th-user_name {
            width: 250px;
            max-width: 250px;
        }
        .th-post {
            width: 240px;
            max-width: 240px;
        }
        .th-department {
            width: 240px;
            max-width: 240px;
        }
        .th-employment_id {
            width: 240px;
            max-width: 240px;
        }
        .th-status {
            width: 100px;
        }
        .th-license {
            width: 85px;
        }
        .th-tel {
            width: 160px;
        }
        .th-emergency1 {
            width: 160px;
        }
        .th-emergency2 {
            width: 160px;
        }
        .th-holiday_min {
            width: 85px;
        }
        .th-holiday_used {
            width: 85px;
        }
        .th-holiday_rest {
            width: 85px;
        }
        .th-license_date {
            width: 85px;
        }
        .th-license_expire_date {
            width: 85px;
        }
    }
    td {
        text-align: left;
    }
    td.td-user_name {
        width: 250px;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    td.td-user_kana {
        width: 250px;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    td.td-occupation {
        width: 250px;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .user-badge-required {
        margin-bottom: 8px;
        width: auto;
    }

    // switch幅調整
    .user-switch {
        // ボタン幅調整
        .btn-group {
            .btn-default {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }

    .modal-config-occupation-detail-setting {
        max-height: 150px;
        overflow-y: scroll;
    }
    .modal-config-occupation-detail-display {
        border-bottom: 0;
    }
    #user-occupation-history-modal {
        min-width: 50vw;
        .modal-body {
            overflow-y: scroll;
            .card-body {
                padding: 0;
            }
        }
    }
    //印刷ボタン運行管理者
    .dropdown-menu {
        margin-right: 145px;
    }
    .functions {
        overflow-x: auto;
        flex-wrap: nowrap;
        list-style-type: none;
        overflow-y: hidden;
        scrollbar-width: thin;/*for firefox*/
        scrollbar-color: #4c87c7 #d7d7d7;/*for firefox*/
    }
    .functions::-webkit-scrollbar {
        height: 10px;
    }
    .functions::-webkit-scrollbar-track {
        background-color: #d7d7d7;
        border-radius: 3px;
    }
    .functions::-webkit-scrollbar-thumb {
        background-color: #4c87c7;
        border-radius: 3px;
    }
    @media screen and (max-width: 575px) {
        .functions {
            margin: 0 0 5px 0 !important;
            .btn-app {
                margin: 0 0 0 10px !important;
            }
        }
    }
    .user-paid-holiday-histories {
        th, td {
            vertical-align: middle;
            text-align: center;
        }
        .lapse td {
            color: $gray-500;
        }
        td.minus {
            color: $danger;
        }
    }
}
