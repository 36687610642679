#section-csv-setting-modal {
    #section-csv-setting-modal-csv-error {
        width: 100%;
        max-height: 150px;
        list-style-type: none;
        color: $danger;
        font-size: 0.9rem;
        overflow-y: scroll;
        border: 1px solid $gray-500;
    }
    #section-csv-setting-modal-csv-submit-message {
        color: $green;
        font-size: 0.9rem;
    }
}