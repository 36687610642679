.dashboard-page {

    #menu-wrapper {
        width: 100%;
        position: relative;
        #dashboard-menu {
            //height: 140px;
            flex-wrap: nowrap;
            list-style-type: none;
            overflow-x: auto;
            overflow-y: hidden;
            scrollbar-width: thin;/*for firefox*/
            scrollbar-color: #4c87c7 #d7d7d7;/*for firefox*/
        }
        #dashboard-menu::-webkit-scrollbar {
            height: 10px;
        }
        #dashboard-menu::-webkit-scrollbar-track {
            background-color: #d7d7d7;
            border-radius: 3px;
        }
        #dashboard-menu::-webkit-scrollbar-thumb {
            background-color: #4c87c7;
            border-radius: 3px;
        }
        #dashboard-menu::-webkit-scrollbar-corner {
            display: none;
        }

        .btn-scroll {
            display: inline-block;
            position: absolute;
            top: 20%;
            background: rgb(255,255,255, 0.75);
            padding: 20px 5px;
            z-index: 500;
            cursor: pointer;
        }
        .btn-next {
            right: 0;
            border-radius: 3px 0 0 3px;
            box-shadow: -1px 1px 5px gray;
        }
        .btn-prev {
            left: 0;
            border-radius: 0 3px 3px 0;
            box-shadow: 1px 1px 5px gray;
        }
    }
    .fade:not(.tab-pane.active) {
        display:none;
    }
    .nav-link[data-widget="navbar-search"] {
        display: none;
    }
    .navbar-search-block {
        display: none;
    }
    .btn-task-close{
        background-color: $gray-600;
        filter: grayscale(100%);
        pointer-events: none;
        color: white;
    }
    .dashboard-information {
        height: 700px;
        .tab-border{
            height: 655px;
        }
        .tab-content {
            overflow-y: scroll;
            .information-not-found {
                display: inline-block;
                width: 100%;
                text-align: center;
                position: absolute;
                top: 270px;
                left: 0;
            }
            .tab-panel:not(.active) {
                display: none !important;
            }
        }
        .notice-count-block {
            display: inline-block;
            position: absolute !important;
            top: -17px;
            right: -6px;
        }
        .information-list {
            height: 653px;
            .list-group-item {
                padding: 3px;
                div {
                    display: flex;
                    align-items: center;
                    padding: 0;
                    cursor: pointer;

                    .btn {
                        padding: 0;
                    }
                }
                .information-btn-box {
                    flex-direction: column;
                    justify-content: center;
                }
                @media screen and (min-width: 992px) and (max-width: 1199px) {
                    div {
                        font-size: 0.8rem;
                    }
                }
            }
        }
        .information-font{
            font-weight: 700;
            font-size: 15px;
        }
        .infomation-pagenation {
            display: none;
            width: 100%;
            height: 60px;
            text-align: center;
            &.default {
                display: block;
                position: absolute;
                height: 100%;
                img {
                    width: 45px;
                    margin: 50% auto;
                }
            }
            img {
                width: 45px;
                margin: 5px auto;
            }
        }
    }

    .dashboard-task {
        height: 700px;
        .card-body {
            overflow-y: scroll;
        }
        .task-not-found {
            display: inline-block;
            position: absolute;
            top: 315px;
            width: 100%;
            font-size: 1rem;
            text-align: center;
        }
        .table {
            width: 98%;
            margin: 0 1%;
            tr{
                td {
                    padding: 8px 1px !important;
                    &.task-td1{
                        text-align: center;
                        width: 20%;
                    }
                    &.task-td2{
                        text-align: left;
                        word-wrap: break-word;
                    }
                    &.task-td3{
                        text-align: center;
                        width: 8%;
                    }
                }
            }
        }
        @media screen and (min-width: 992px) and (max-width: 1199px) {
            .table {
                transform: scale(0.8);
            }
        }
    }

    .dashboard-schedule {
        height: 700px;
        .table {
            width: 98%;
            margin: 0 1%;
            text-align: center;
        }
        .table {
            tr {
                height: 40px;
                td {
                    font-size: 1.8rem;
                    &.saturday {
                        color: $font-color-blue;
                    }
                    &.sunday {
                        color: $font-color-pink;
                    }
                    &.dropzone.dragover {
                        background-color: $gray-200;
                    }
                    &.today {
                        color: $font-color-orange;
                        font-weight: bold;
                    }
                    &.active {
                        background-color: $table-bg-color-blue;
                    }
                    .calendar-date-value {
                        position: relative;
                        .notice-count {
                            display: inline-block;
                            position: absolute !important;
                            top: -7px;
                            right: -22px;
                            min-width: 20px;
                            height: 20px;
                            font-size: 14px;
                            line-height: 12px;
                            border-radius: 15px;
                        }
                        @media screen and (min-width: 992px) and (max-width: 1199px) {
                            .notice-count {
                                top: -7px;
                                right: -15px;
                                min-width: 18px;
                                height: 18px;
                                font-size: 12px;
                            }
                        }
                    }
                    .day {
                        display: block;
                    }
                }
            }
            @media screen and (min-width: 992px) and (max-width: 1199px) {
                tr {
                    td {
                        font-size: 1.5rem;
                    }
                }
            }
        }
        .schedule{
            height: 238px;
            .schedule-list{
                overflow-y: scroll;
                height: 97%;
                .schedule-list-group-item{
                    position: relative;
                    display: block;
                    border: 0.5px solid;
                    border-color: rgb(0, 0, 0, 0.125);
                    padding: 0.6px;
                    div {
                        align-items: center;
                        justify-content:center;
                        padding: 0;
                    }
                }
            }
        }
        @media screen and (min-width: 992px) and (max-width: 1199px) {
            .schedule {
                transform: scale(0.8);
            }
        }
    }
}

.dashboard-page ~ .tooltip > .tooltip-inner {
    max-width: 350px;
    width: auto;
}
