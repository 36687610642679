#rollcall-malfunction-setting-modal-form {
    .master-check-inputs {
        .btn {
            width: 100%;
            text-align: left;
        }
    }
    #rollcall-malfunction-setting-modal-malfunction_other_content {
        min-height: 100px;
    }
}

#rollcall_drive_judgement-setting-modal {
    textarea[name="reason"] {
        min-height: 70px;
    }
}
.rollcall-page ~ .tooltip > .tooltip-inner {
    max-width: 350px;
    width: auto;
}

.rollcall-page {
    .header-top {
        top:-1px;
    }
    /** 点呼簿モーダルの設定
    *   thead th
    *   → モーダルのヘッダー部を設定
    *     運転者名を除いた各項目をcalcで均等計算し幅を設定
    *
    *   thead tr.header
    *   → 点呼詳細モーダルのヘッダー(業務前/中間/業務後)を設定
    *   thead tr.header-detail
    *   → 点呼詳細モーダルのヘッダー項目を設定
    *
    *   tbody th.driver
    *   → 運転者名の幅を設定
    *
    *   tbody td
    *   → DBから取得し表示するデータの設定
    *     エラーか点呼未承認に応じて背景色を変更する (&.error, &.not-authlized)

    *   tbody td.size-change
    *   → 特定データのサイズ変更
    *   tbody td.data
    *   → 点呼詳細モーダルの各データ部の設定
    *   tbody td.data-ok-ng
    *   → 点呼詳細モーダルの特定データの設定 (ok/ng)
    *
    *   th.driver-name
    *   → 特定ヘッダーを設定 (運転者名)
    *   th.rollcaller
    *   → 特定ヘッダーを設定 (点呼執行者)
    *   td.size-change
    *   → 特定データのフォントサイズを設定
    */
    .rollcall-day-table {
        thead th {
            padding: 0;
            font-size: 15px;
            vertical-align: middle;
            text-align: center;
            background-color: $bss_color_logo_blue;
            color: $bss_color_background;
            width:calc(100% / 24);
        }
        thead tr.header {
            font-size: 22px;
        }
        thead tr.header-detail{
            font-size: 26px;
        }
        thead th.header-before-after {
            background-color: #207CA1;
        }
        thead th.header-middle {
            background-color: #5094a1;
        }

        tbody td {
            padding: 0;
            font-size: 15px;
            height: 50px;
            vertical-align: middle;
            text-align: center;
            &.error {
                background-color: $table-bg-color-pink;
            }
            .alc-img {
                max-width: 35px;
            }
        }
        // 点呼詳細モーダルの各データ部
        tbody td.data {
            padding: 0;
            font-size: 15px;
            vertical-align: middle;
            &.error {
                background-color: $table-bg-color-pink;
            }
        }
        // 点呼詳細モーダルのマルバツサイズ
        tbody td.data-ok-ng {
            padding: 0;
            font-size: 15px;
            vertical-align: middle;
            &.error {
                background-color: $table-bg-color-pink;
            }
        }
        th.driver-name {
            width:150px;
        }
        th.rollcall_method_type {
            max-width:120px;
            width:120px;
        }
        th.rollcaller {
            max-width:150px;
            width:150px;
        }
        th.ok-ng {
            width:45px;
        }
        td.size-change{
            font-size: 30px;
        }
        td.rollcall_method_type{
            max-width:120px;
            width:120px;
        }
        td.rollcaller {
            max-width:150px;
            width:150px;
            // overflow-wrap: break-word;
            // word-wrap: break-word;
            white-space: normal;
        }
        td.ws-normal {
            white-space: normal;
        }
    }

    // 虫眼鏡を消す
    .nav-link[data-widget="navbar-search"] {
        display: none;
    }
    .navbar-search-block {
        display: none;
    }

    .image-button,
    .btn-app {
        margin: 0 0 10px 3px !important;
    }
    /** 点呼簿画面のカレンダー表示設定 */
    .rollcall-calendar-text-position {
        text-align: center;
    }

    /** 点呼割合の全体表示設定
    *   .rate
    *   → 点呼割合の全体表示設定
    *     点呼執行者 or 点呼補助者に応じて各設定を実施 (&.enforcer-rate, &.assistant-rate)
    *   img
    *   → 点呼割合に使用されている枠の画像設定
    *     画像上に文字を表示させるために、position:absoluteを指定
    *   .rate-value
    *   → 点呼割合に表示される値の設定
    *     画像上に文字を表示させるために、position:relativeを指定
    *     top, leftで表示位置の設定を行い、fontsizeを考慮した位置設定をtransformで実施
    */
    .approval-block {
        .rate {
            display: inline-block;
            width: 124px;
            height:124px;
            background-repeat: no-repeat;
            background-size: 124px 124px;
            background-position: 0 0;
            &.enforcer-rate {
                background-image: url("/assets/img/common/rollcall-executor-discount.png");
            }
            &.assistant-rate {
                background-image: url("/assets/img/common/rollcall-holder-discount.png");
            }
            .rate-value {
                text-align: center;
                font-size: 3rem;
                padding-top: 2rem;
            }
        }
        @media screen and (max-width: 767px) {
            .rate {
                width: 100px;
                height:100px;
                background-size: 100px 100px;
                .rate-value {
                    text-align: center;
                    font-size: 2rem;
                    padding-top: 1.8rem;
                }
            }
        }
    }

    .btn-app:active, .btn-app:focus {
        box-shadow: none;
    }
    .modal-rollcall {
        max-width: inherit;
        position: relative;
        width: 95%;
    }
    @media screen and (max-width: 575px) {
        .modal-rollcall {
            width: auto;
        }
    }
    .modal-rollcall-header {
        padding: 0;
    }
    .modal-rollcall-body {
        height: 800px;
        margin: 15px;
        overflow-y: scroll;
    }

    // 点呼登録モーダルのトグルボタン設定
    .modal-rollcall-add-btn {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .modal-rollcall-add-btn-setting {
        padding-left: 0;
        padding-right: 0;
    }

    // 印刷ボタン設定
    .rollcall-print-btn {
        display: flex;
        .dropdown-menu {
            margin-right: 145px;
        }
    }
    .image-button-with-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
            position: relative;
            transform: none;
            left:auto;
            top:auto;
        }
        .lh-s {
            line-height: 1;
        }
        .lh-m {
            line-height: 1.2;
        }
    }

    .search-section-method {
        display: inline-block;
        font-size: 0.8rem;
        input {
            margin-top: 3px;
        }
        .checkbox-label {
            vertical-align: top;
        }
    }

    #rollcall-detail-modal {
        .modal-header {
            .modal-title {
                line-height: 50px;
            }
        }
    }

    #rollcall-setting-modal {
        .malfunction-setting {
            width: 100px;
            color: white;
            font-size: 1rem;
            font-weight: bold;
            position: absolute;
            top: 8px;
            right: 8px;
            line-height: 18px;
        }
        .modal-body {
            max-height: 900px;
        }
        .modal-content {
            height: 900px;
        }
        .drinking_value_spacer {
            padding-top:10px;
        }
        select.multi-select:disabled + .ms-parent.multi-select,
        select.multi-select:disabled + .ms-parent.multi-select > * {
            background-color: #e9ecef;
        }
    }

    #rollcall-print-confirm-modal {
        & .modal-dialog {
            position: relative;
            width: auto;
            pointer-events: none;
        
            @media screen and (min-width: 576px) {
                max-width: 500px;
                margin: auto;
            }
        }

        & .checkbox-row {
            padding-top: 25px;
        }

        & label.input-label {
            font-weight: unset;
        }
    }
    
    #rollcall-config-modal {
        .modal-content {
            min-height: 550px;
        }
        .rollcall-config-box {
            display:flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .rollcall-config-item {
                width: 32%;
            }
        }
    }
    #rollcall-drive_judgement_setting_list-modal {
        .drive_judgement_setting_list_model-body {
            max-height: 700px;
            .list-area {
                max-height: 500px;
            }
        }
    }

    #rollcall-print-select-modal {
        .modal-body {
            min-height: 500px;
        }
    }

    //master-switch 点呼方法 を枠内に収める
    div.master-switch>label.px-3 {
        min-width: 60px;
    }

    td.is_expectation {
        background: #ffffe3;
        &.unauthorized {
            background: #ffffB0;
        }
    }

    td.is_cancel {
        background: #d8deef;
    }

    #rollcall-record-modal {
        #rollcall-recorded-file {
            text-align: center;
        }
    }

    #search-form {
        .rollcall-list-search-left-box {
            width : 55%;
        }
        .rollcall-list-search-right-box {
            width : 45%;
            justify-content: flex-end;
        }
        @media screen and (max-width: 1700px) {
            .rollcall-list-search-left-box {
                width : 65%;
            }
            .rollcall-list-search-right-box {
                width : 35%;
            }
        }
        .between {
            padding: 38px 10px 0px 10px;
        }
    }
    #expectation_list table tr.unauthorized {
        background-color: $table-bg-color-pink;
    }
}
