.drive_judgement-page {
    #search-form {
        .between {
            padding: 38px 10px 0px 10px;
        }
    }

    // 虫眼鏡を消す
    .nav-link[data-widget="navbar-search"] {
        display: none;
    }
    // 検索キーワード非表示
    .navbar-search-block {
        display: none;
    }
}
#drive_judgement-setting-modal {
    textarea[name="reason"] {
        min-height: 70px;
    }
}

#drive_judgement-code-modal {
    .pass_code {
        font-size: 11rem;
        line-height: 11rem;
        @media (max-width: 992px) {
            font-size: 8rem;
            line-height: 8rem;
        }
    }
}
