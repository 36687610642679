
.contract-page {
    .contract-option-list {
        border: solid 1px #ced4da;
        padding: 15px 35px 10px 35px;
        max-height: 450px;
        overflow-y: scroll;
        .contract-option-detail {
            border: solid 1px #ced4da;
            border-radius: 5px;
            padding: 15px 15px 10px 15px;
            margin-bottom:27px;
            max-height: 280px;
            overflow-y: scroll;
        }
    }
    .hidden {
        visibility:hidden;
    }
}