
body {
    // font-family: "Open Sans", "Helvetica Neue", Helvetica, "Arial", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
    font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 1rem;
}
$idx: 50;
@while $idx < 100 {
    .min-vh-#{$idx} {
        min-height: $idx + vh !important;
    }
    .max-vh-#{$idx} {
        max-height: $idx + vh !important;
    }
    .min-vw-#{$idx} {
        min-width: $idx + vh !important;
    }
    .max-vw-#{$idx} {
        max-width: $idx + vh !important;
    }
    $idx: $idx + 5;
}
textarea {
    min-height: 200px;
}
.row {
    margin-right: 0;
    margin-left: 0;
}
.table.table-head-fixed thead tr:nth-child(1) th {
    top: -1px;
    box-shadow: none;
}
.table-sm th, .table-sm thead th {
    border: none;
}
.table-sm td {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}
table th.sort-column {
    cursor: pointer;
    .desc-icon, .asc-icon {
        display: none;
    }
    &.asc:hover {
        .asc-icon { display: inline-block; }
    }
    &.desc:hover {
        .desc-icon { display: inline-block; }
    }
    &.asc.active {
        .asc-icon { display: inline-block; }
    }
    &.desc.active {
        .desc-icon { display: inline-block; }
    }
    &.asc.active:hover {
        .desc-icon { display: inline-block; }
        .asc-icon { display: none; }
    }
    &.desc.active:hover {
        .asc-icon { display: inline-block; }
        .desc-icon { display: none; }
    }
}
.dropdown-item{
    cursor: pointer;
    .i {
        margin-left: 5px;
    }
}
.bs-stepper {
    .step-trigger {
        padding: 10px 20px 10px 20px !important;
    }
    .step-trigger.error {
        position: relative;
        &::after {
            display: inline-block;
            content: "!";
            position: absolute !important;
            top: 8px;
            right: 6px;
            min-width: 20px;
            height: 20px;
            font-size: 1.4rem;
            color: $danger;
        }
    }
    .active {
        .step-trigger {
            color: $primary;
            .bs-stepper-circle {
                background-color: $primary;
            }
        }
    }
    .step {
        position: relative;
    }
}
@media screen and (max-width: 991px) {
    .bs-stepper {
        transform: scale(0.8);
        .step-trigger {
            padding: 0 0 0 0 !important;
        }
        .line {
            flex: 1 0 22px;
        }
    }
}
.bs-stepper-content {
    .bs-stepper-panel.fade {
        display: none;
    }
    .nav-tabs .nav-link.error {
        position: relative;
        padding-right: 30px;
        &::after {
            display: inline-block;
            content: "!";
            position: absolute !important;
            transform: translate(-50%, -50%);
            font-weight: bold;
            top: 43%;
            right: -6px;
            min-width: 20px;
            font-size: 1.4rem;
            color: red;
        }
    }
}

.invalid-feedback {
    display: block;
}
.annotation {
    color: #dc3545;
}
.prescribed-holiday {
    color:blue;
}
.legal-holiday {
    color:red;
}

.text-color-sunday {
    color:red;
}
.text-color-saturday {
    color:blue;
}

.btn-group .active,
.master-switch .active,
.employment-switch .active,
.occupation-switch .active {
    color: $white;
    background-color: $primary;
}

.large-font-switch {
    label[data-label="×"] {
        padding-top: 2px !important;
    }
    label {
        font-size: 1.8rem;
        line-height: 1.0;
        padding: 0;
        font-weight: 100 !important;
    }
}

.select2-container .select2-selection--single {
    height: 38px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 38px;
}

.ms-choice {
    border: none;
    span {
        line-height: 37px;
    }
}
.image-preview {
    position: relative;
    width: 100%;
    height: 105px;
    border-radius: 4px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, .3);
    display: inline-block;
    margin-top: 10px;
    img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.filename-preview {
    min-width: 90px;
    width: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
div.file-delete, span.file-delete {
    label {
        font-weight: normal !important;
    }
}
form .form-check-label {
    display: inline-block;
    margin-right: 10px;
}
.master-check-inputs {
    input {
        margin-right: 10px;
    }
}
.form-zipcode {
    .zipcode, .zipcode-button {
        display: inline-block;
        vertical-align: top;
    }
}
.form-address {
    .prefecture, .municipality, .address {
        display: inline-block;
        vertical-align: top;
    }
    .prefecture {
        width: 150px;
    }
    .municipality {
        width: 250px !important;
    }
    .address {
        width: 100% !important;
    }
}
.display-columns {
    list-style-type: none;
    margin-block-start: 0;
    padding-inline-start: 0;
    margin: 0 5px;
    li {
        display: inline-block;
        .check-button {
            cursor: pointer;
            border: 1px solid $gray-500;
            padding: 3px 8px !important;
            margin: 2px !important;
            border-radius: 8px;
            &.active {
                border: 1px solid $primary;
                background-color: $primary;
                color: $white;
            }
            input {
                display: none !important;
            }
        }
    }
}
.detail-overflow {
    overflow-y: scroll;
    max-height: 77vh;
}
.sticky-top-zindex1 {
    position:sticky;
    top:0;
    z-index:1;
}
.input-group {
    .updated-file-preview {
        position: absolute;
        left: 130px;
        top: 5px;
        .updated-file-filename {
            display: inline-block;
            width: 65px;
            margin-left: 0.25rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
.card-header {
    background-color: $bss_color_logo_blue;
    color: $bss_color_background;
}
.card-title {
    background-color: $bss_color_logo_blue;
    color: $bss_color_background;
}
.modal-header {
    background-color: $bss_color_logo_blue;
    color: $bss_color_background;
}
.list-area {
    display: block;
    width: 100%;
    overflow-x: auto;
}
.print-area {
    position: fixed;
    left: -9999px;
    .print-content {
        width: 2480px;
        height: 100%;
        padding: 5px 5px 5px 5px;
        .modal-body-detail {
            width: 98%;
            max-height: 90% !important;
            font-size: 30px !important;
            margin-right: auto;
            margin-left: auto;
            padding-left: 20px;
            span{
                font-size: 35px !important;
                .edit-by-cloud {
                    color: $black;
                }
            }
            table , th {
                font-size: 35px !important;
                border: 1px solid $gray-800 !important;
                .edit-by-cloud {
                    color: $black;
                }
            }
            td {
                font-size: 35px !important;
                border: 1px solid $gray-800 !important;
                .edit-by-cloud {
                    color: $black;
                }
            }
        }
    }
}

.menu-buttons {
    .menu-button {
        position: relative;
        padding: 5px;
        margin: 0 8px;
        width: 125px;
        height: 125px;
        &.active {
            background-color: $primary;
            img {
                filter: invert(88%) sepia(61%) saturate(0%) hue-rotate(229deg) brightness(107%) contrast(101%);
            }
        }
        img {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            vertical-align: middle;
            max-width: 70%;
            max-height: 70%;
        }
        .menu-text {
            display: inline-block;
            position: absolute;
            bottom: 0;
            font-size: 18px;
            font-weight: bold;
            left: 0;
            right: 0;
        }
    }
    .menu-button_close {
        display: inline-block;
        position: relative;
        padding: 5px;
        margin: 0px 8px;
        width: 125px;
        height: 125px;
        background-color: $gray-500;
        filter: grayscale(100%);
        pointer-events: none;
        img {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            vertical-align: middle;
            max-width: 70%;
            max-height: 70%;
        }
        .menu-text {
            position: absolute;
            bottom: 0;
            font-size: 18px;
            font-weight: bold;
            left: 0;
            right: 0;
        }
    }
}

.notice-count {
    display: inline-block;
    position: absolute !important;
    top: 6px;
    right: 6px;
    min-width: 20px;
    height: 20px;
    font-size: 14px;
    line-height: 12px;
    border-radius: 15px;
}

.notice-count-block {
    display: inline-block;
    position: absolute !important;
    top: -10px;
    right: 6px;

    .notice-count {
        position: unset !important;
        top: unset;
        right: unset;
    }
}

.notice-count-block-vertical {
    position: absolute !important;
    width: 20px;
    margin: 0 0 0 20px;

    .notice-count {
        position: unset !important;
        top: unset;
        right: unset;
    }
}
@media screen and (max-width: 1199px) {
    .notice-count-block-vertical {
        position: absolute !important;
        width: unset;
        margin: -20px 0 0 50px;


        .notice-count {
            position: unset !important;
            top: unset;
            right: unset;
        }
    }
}
.notice-count-block-list {
    float: right;

    .notice-count {
        position: unset !important;
        top: unset;
        right: unset;
    }
}

.calendar {
    .table {
        th {
            padding: 0;
        }
        td.calendar-item {
            position: relative;
            box-shadow: 0 5px 0px 0px $primary inset;
            height: 100px;
            font-size: 50px;
            padding: 0;
            &.error {
                box-shadow: 0 5px 0px 0px $danger inset;
                color: $danger;
                a {
                    color: $danger;
                }
            }
            &.not-result {
                box-shadow: none;
                background-color: $gray-500;
                span {
                    color: $white;
                }
            }
            a {
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .calendar {
        .table {
            td.calendar-item {
                height: 80px;
                font-size: 2rem;
            }
        }
    }
}

.year-picker-inputs.icons {
    width: 150px;
    height: 50px;
    margin: 0 25px;
    .year-picker {
        width: 150px;
        height: 50px;
        position: absolute;
        bottom: 0;
        left: 25px;
        font-size: 1.45rem;
        text-align: center;
    }
    &.no-icons {
        height: 38px;
        .year-picker {
            width: 100%;
            left: 0;
            right: 0;
        }
    }
    .back, .forward, .current {
        width: 25px;
        height: 25px;
        position: absolute;
        bottom: 0;
        line-height: 23px;
        text-align: center;
        vertical-align: top;
        color: black;
        border: 1px solid $gray-400;
        border-radius: 15px;
    }
    .back {
        left: 0;
        i { margin-right: 3px; }
    }
    .forward, .current {
        left: 175px;
        i { margin-left: 3px; }
    }
    .current {
        bottom: 25px;
        font-size: 13px;
        i { margin-left: 0; }
    }
    ::after {
        content: unset;
        clear: both;
    }
}
@media screen and (max-width: 767px) {
    .year-picker-inputs.icons {
        width: 120px;
        .year-picker {
            width: 120px;
            font-size: 1.2rem;
        }
        .forward, .current {
            left: 145px;
        }
    }
}
.month-picker-inputs.icons {
    width: 150px;
    height: 50px;
    margin: 0 25px;
    .month-picker {
        width: 150px;
        height: 50px;
        position: absolute;
        bottom: 0;
        left: 25px;
        font-size: 1.45rem;
        text-align: center;
    }
    .back, .forward, .current {
        width: 25px;
        height: 25px;
        position: absolute;
        bottom: 0;
        line-height: 23px;
        text-align: center;
        vertical-align: top;
        color: black;
        border: 1px solid $gray-400;
        border-radius: 15px;
    }
    .back {
        left: 0;
        i { margin-right: 3px; }
    }
    .forward, .current {
        left: 175px;
        i { margin-left: 3px; }
    }
    .current {
        bottom: 25px;
        font-size: 13px;
        i { margin-left: 0; }
    }
    ::after {
        content: unset;
        clear: both;
    }
}
@media screen and (max-width: 767px) {
    .month-picker-inputs.icons {
        width: 120px;
        .month-picker {
            width: 120px;
            font-size: 1.2rem;
        }
        .forward, .current {
            left: 145px;
        }
    }
}

.checkbox-label, .radio-label {
    margin-right: 10px;
    font-weight: normal;
}

.image-button {
    position: relative;
    padding: 5px;
    margin: 0 auto;
    &.dropdown-toggle::after {
        border-top: none;
    }
    &.active {
        background-color: $primary;
        img {
            filter: invert(88%) sepia(61%) saturate(0%) hue-rotate(229deg) brightness(107%) contrast(101%);
        }
    }
    &:hover {
        img {
            opacity: 0.95;
        }
    }
    // 点呼簿の印刷ボタン
    &.modal-rollcall-print {
        width: 50px;
        height: 50px;
    }
    // 社員台帳の印刷ボタン
    &.modal-user-print {
        width: 50px;
        height: 50px;
    }
    // 車両台帳の印刷ボタン
    &.modal-vehicle-print {
        width: 50px;
        height: 50px;
    }
    // 事故記録の印刷ボタン
    &.modal-accident-print {
        width: 50px;
        height: 50px;
    }
    // 出勤簿の印刷ボタン
    &.modal-attendance-print {
        width: 50px;
        height: 50px;
    }
    &.modal-account-description {
        width: 50px;
        height: 50px;
    }
    
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        vertical-align: middle;
        max-width: 80%;
        max-height: 70%;
        opacity: 0.75;
        &.image-icon{
            max-height: 50%;
        }
    }
}
.icon-button {
    &.active {
        background-color: $primary;
        color: $white;
    }
    &[disabled] {
        background-color: $white;
        color: #ccc;
        cursor: not-allowed !important;
        
    }
}
.icon-close-area {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem 1rem 0 0;
}
.tab-list {
    &.nav-tabs .nav-link {
        border-color: $bss_color_logo_blue !important;
    }
    &.nav-tabs.flex-column .nav-link.active, &.nav-tabs.flex-column .nav-item.show .nav-link {
        border-color: $bss_color_logo_blue !important;
    }
    &.nav-tabs.flex-column .nav-link {
        border-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }
    .nav-link {
        border: 1px solid $gray-300;
        background-color: $white;
        position: relative;
        &.active {
            background-color: $bss_color_logo_blue;
            color: $white;
        }
    }
}

.tree {
    list-style-type: none;
    margin: 0 0 0 10px;
    padding: 0;
    position: relative;
    li {
        position: relative;
        margin: 0;
        padding: 2px 0px 2px 20px;
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 13px;
            left: 5px;
            width: 12px;
            height: 0;
            border-top: 1px solid $gray-400;
        }
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 5px;
            width: 0;
            height: 100%;
            border-left: 1px solid $gray-400;
        }
        &:last-child::after {
            height: 14px;
        }
        &.last-child::after {
            height: 14px;
        }
    }
    &::before, &::after {
        border: none;
    }
    i {
        margin-right: 8px;
    }
}

.header-form {
    height: 21px;
    .btn-group {
        vertical-align: top;
    }
    input, button, select, label {
        margin-top: 1px;
        height: 20px;
        line-height: 11px;
        font-size: 16px;
    }
}

.is_dragging {
    background-color: $gray-200;
}

.not-found {
    margin: 100px auto;
    p {
        font-weight: bold;
        margin: 30px auto;
    }
}

#overlay {
    display: none;
    position: fixed;
    left: 0; top: 0;
    width: 100%; height: 100%;
    background: rgba(0, 0, 0, .2);
    z-index: 2147483647;
    #overlay-inner {
        position: absolute;
        margin-top: calc(50% - 25%);
        height: 70px;
        top: 0; bottom: 0; left: 0; right: 0;
        width: 100%;
        text-align: center;
        img {
            height: 100%;
        }
    }
}

.dragover {
    background-color: $gray-300;
}

.modal-header .close, .modal-header .mailbox-attachment-close,
.modal-header .files-setting-close, .modal-header .files-dispose-close {
    padding: 1rem;
    margin: -2rem -6rem -4rem auto;
    z-index: 0;

    &.modal-rollcall {
        padding: 10px 16px 0 2px;
        margin: -16px -32px -16px auto;
    }

    &.modal-user {
        margin: -16px -32px -16px auto;
    }

    &.modal-vehicle {
        margin: -16px -32px -16px auto;
    }
}

@media screen and (max-width: 575px) {
    .modal-header .close, .modal-header .mailbox-attachment-close,
    .modal-header .files-setting-close, .modal-header .files-dispose-close {
        height: 51px;
        padding: 0 !important;
        margin: -10px 0 0 0;
        z-index: 0;
        zoom:80%;
    }
}

.close, .mailbox-attachment-close,
.modal-header .files-setting-close, .modal-header .files-dispose-close {
    float: right;
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 1;
}

.modal-header .files-setting-close,
.modal-header .files-dispose-close {
    border: none;
    background-color: transparent;
}

.close::after,
.files-setting-close::after,
.files-dispose-close::after {
    content: url(../img/common/close-white.png);
    filter: brightness(1) invert(0);
}
.pointer {
    cursor: pointer;
}
.copy {
    display: none;
}
.display-none {
    display: none !important;
}
.icon-alert-danger, .icon-alert-warning, .icon-alert-secondary {
    font-size: 0.8rem;
    padding: 4px 7px;
    margin: 1px;
}
.icon-alert-secondary {
    background-color: $gray-200;
}

.wp-300 { width: 300px; }
.wp-200 { width: 200px; }
.wp-175 { width: 175px; }
.wp-150 { width: 150px; }
.wp-125 { width: 125px; }
.wp-100 { width: 100px; }
.wp-85 { width: 75px; }
.wp-75 { width: 75px; }
.wp-50 { width: 50px; }
.wp-25 { width: 25px; }

.type-csv {
    &::before {
        content: "CSV";
        display: block;
        position: absolute;
        margin: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
        font-size: 9px;
        color: $gray-800;
    }
}

table th, table td,
.table th, .table td  {
    vertical-align: middle;
    padding: 5px;
    font-size: 1.0rem;
}
tr.hover:hover {
    * {
        cursor: pointer;
    }
}

table[role='list'] {
    width:100%;
    th, td  {
        padding: 6px 8px;
    }
    thead {
        text-align: center;
        background-color: $bss_color_logo_blue;
        color: $white;
    }
    tbody {
        tr.disabled:nth-of-type(odd) {
            background-color: #E2E5E9 !important;
            color: #5A5A5A !important;
        }
        tr.disabled:nth-of-type(2n) {
            background-color: #D2D6DE !important;
            color: #5A5A5A !important;
        }
        tr.disabled:hover {
            background-color: #B5BBC8 !important;
            color: #000000 !important;
        }
        tr.hover:hover {
            background-color: #ffffe3 !important;
            color: #000000 !important;
        }
    }
}

canvas {
    max-width: 92%;
    margin: 0 auto;
}

.week-saturday td { background-color: $table-bg-color-blue; }
.week-sunday td { background-color: $table-bg-color-pink; }

.zipcode {
    width: 150px !important;
}
.municipality {
    width: 150px !important;
}

.btn-group-toggle {
    label {
        min-width: 80px;
    }
}
.unset-width {
    .btn-group-toggle {
        label {
            min-width: unset !important;
        }
    }
}
@media screen and (max-width: 767px) {
    .btn-group-toggle {
        label {
            min-width: unset;
        }
    }
}

label.input-label {
    margin-right: 5px;
}

.badge-print-type {
    color: #FFF;
}

.tooltip-window { /* 補足説明するテキストのスタイル */
    position: relative;
    cursor: pointer;
    padding: 0 5px;
    font-size: 0.9em;
}

.balloon-bottom { /* ツールチップのスタイル */
    width: fit-content; /* 横幅 */
    position: absolute;
    top: 80%; /* Y軸の位置 */
    left: 50%;
    transform: translateX(-50%);
    margin-top: 15px; /* テキストとの距離 */
    padding: 8px;
    border-radius: 10px; /* 角の丸み */
    background-color: #666; /* ツールチップの背景色 */
    font-size: 0.9em;
    color: #fff;
    text-align: center;
    visibility: hidden; /* ツールチップを非表示に */
    opacity: 0; /* 不透明度を0％に */
    z-index: 1;
    transition: 0.5s all; /* マウスオーバー時のアニメーション速度 */
}

.balloon-bottom::before { /* 吹き出しの「しっぽ」のスタイル */
    content: "";
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom: 12px solid #666; /* 最後の値は「ツールチップの背景色」と合わせる */
    position: absolute;
    bottom: 99%;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip-window:hover .balloon-bottom { /* マウスオーバー時のスタイル */
    top: 100%; /* Y軸の位置 */
    visibility: visible; /* ツールチップを表示 */
    opacity: 1; /* 不透明度を100％に */
}

.paginate,
.pagination-sm { /* ページネーションブロック内のスタイル */
    display: flex !important;
    flex-direction: row !important;
    .btn { /* 削除ボタンなどツール用ボタンのスタイル */
        margin-bottom: 1rem;
        margin-right: 10px;
    }
    .display-setting-link {
        position: relative;
        display: block;
        padding: 0.5rem 0.75rem;
        margin-left: -1px;
        line-height: 1.25;
        color: #4c87c7;
    }
}

/* btn-outline-primary override for multi-check */
.multi-check, .single-check {
    .btn-outline-primary {
        color: #2b2b2b;
        border-color: #ddd;
        background-color: #f8f9fa;
    }
    .btn-outline-primary:hover,
    .btn-outline-primary.hover {
        background-color: #e9ecef;
    }
    .btn-outline-primary:not(:disabled):not(.disabled).active,
    .show > .btn-outline-primary.dropdown-toggle {
        color: #fff;
        background-color: #4c87c7;
    }
}
/* 車両台帳とお知らせの月チェックのみ幅調整 */
/* ブレイクポイント強制 */
@media screen and (max-width: 900px) {
    .vehicle-page {
        .inspection_type-block {
            .multi-check, .single-check {
                display: flex;
                label {
                    writing-mode: vertical-rl;
                    text-orientation: sideways;
                    padding: 0.25rem !important;
                    &.wp-50 {
                        width: unset;
                    }
                }
            }
        }
    }
    .information-page {
        #inspection {
            .multi-check {
                label {
                    writing-mode: vertical-rl;
                    text-orientation: sideways;
                    padding: 0.35rem !important;
                    &.wp-50 {
                        width: unset;
                    }
                }
            }
        }
    }
}

.cursor-default div {
    cursor: default !important;
}
#remote-rollcall-standby-flag, #it-rollcall-standby-flag {
    width: 150px;
    .custom-control-label {
        font-weight: normal;
        margin-top: 8px;
    }
}
#remote-rollcall-standby-flag, #it-rollcall-standby-flag {
    margin-right: 30px;
}
#remote-rollcall-navbar-menu {
    position: relative;
}

.image-modal {
    button.close {
        position: absolute;
        top: 0;
        right: 0.5rem;
        filter: invert(100%) sepia(0%) saturate(1000%) hue-rotate(182deg) brightness(100%) contrast(0%);
    }
}

/* レスポンシブ対応 表示切替 */
@media screen and (min-width: 768px) {
    nav.pc {
        display: block;
    }
    nav.sp {
        display: none;
    }
}
@media screen and (max-width: 767px) {
    nav.pc {
        display: none;
    }
    nav.sp {
        display: block;
    }
}

.list-thumb {
    max-width: 35px;
}

.select-multiple {
    &--label {
        span {
            color: $blue;
            white-space: nowrap;
            font-size: 0.8rem;
            cursor: pointer;
        }
    }
    &--search-text {
        position: relative;
        input {
            padding-left: 30px;
        }
        i {
            position: absolute;
            top: 10px;
            left: 10px;
            opacity: 0.6;
        }
    }
    &--select {
        max-height: 100px;
        option[selected] {
            background-color: #4c87c7;
            color: #fff;
        }
    }
    &--fold-select-panel {
        position:absolute;
        z-index:100;
        width:95%;
        min-height: 200px !important;
    }

}

/* 印刷表示制御用スタイル */

.hide-print {
    display: unset;
}
.only-print {
    display: none;
}

.csv-error {
    width: 100%;
    max-height: 150px;
    list-style-type: none;
    color: #dc3545;
    font-size: 0.9rem;
    overflow-y: scroll;
    border: 1px solid #adb5bd;
}

/* lity.css override */

.lity {
    background-color: rgb(0 0 0 / 50%) !important;
    div.lity-content {
        background-color: #fff !important;
    }
    button.lity-close {
        position: absolute !important;
        top: -1.8rem;
        right: 1.5rem;
        opacity: 40%;
        font-size: 0;
    }
    button.lity-close::after {
        content: url(../img/common/close-white.png);
        filter: brightness(1) invert(0) drop-shadow(2px 2px 4px #000);
    }
}

/* zdc-map common */
div.zdc-map {
    width: 100%;
    min-height: 300px;
    padding: 0 !important;
    overflow: hidden;
}

// ニュースリリースバナー
.news-release-message {
    padding-top: 2px;
    padding-bottom: 2px;
    border-top-width: 0.5px;
    border-bottom-width: 0.5px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-style: solid;
    border-color: #ff9807;
    background-color :#ffc107;
    .close {
        float: right;
        font-size: 1.25rem;
        padding-top: 3px;
        padding-right: 1rem;
        z-index: 0;
    }
    .close::after {
        content : none;
    }
}
// ファイル管理
.files-table {
    .date-picker {
        position: relative;
    }

    .bootstrap-datetimepicker-widget {
        table {
            width:100%;
            th, td  {
                padding: 6px 8px;
                border-right: none !important;
                border-left: none !important;
            }
            thead {
                text-align: center;
                color: #000;
                th  {
                    border: none !important;
                    border-radius: 0rem;
                }
                tr {
                    border: none !important;
                    background-color: #fff !important;
                }
            }
            tbody {
                tr {
                    background-color: #fff !important;
                }
                tr.disabled:nth-of-type(odd) {
                    background-color: #fff !important;
                    color: #5A5A5A !important;
                }
                tr.disabled:nth-of-type(2n) {
                    background-color: #fff !important;
                    color: #5A5A5A !important;
                }
                tr.disabled:hover {
                    background-color: transparent !important;
                    // color: #000000 !important;
                }
                tr.hover:hover {
                    background-color: transparent !important;
                    // color: #000000 !important;
                }
                td {
                    border-top-width: 0px;
                }
            }
        }
    }

    .bootstrap-datetimepicker-widget.dropdown-menu {
        width: 16rem;
    }
}
