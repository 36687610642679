.information-page {
    #information-setting-modal {
        div.errors.col-md-12 {
            max-width: none;
        }
        div.errors {
            position: absolute;
            background-color: #FFF;
            border-radius: 5px;
            z-index: 5000;
            height: auto;
            width: auto !important;
            clear: both;
            box-shadow: 0 2px 5px 0 rgb(0 0 0 / 50%);
            left: 0;
            white-space: nowrap;
            padding: 2px;
        }
        div.w35p {
            width:35%;
        }
    }
}
