.inspection_type-page {
    #inspection_type-setting-modal {
        @media screen and (max-width: 992px) {
            .modal-content {
                width: 600px;
            }
        }
        @media screen and (min-width: 992px) and (max-width: 1199px) {
            .modal-content {
                width: 800px;
            }
        }
        @media screen and (min-width: 1192px) {
            .modal-content {
                width: 1000px;
            }
        }
    }
}