// labor
.labor-page{
  .align-right {
      text-align: right !important;
  }
  .align-left {
      text-align: left !important;
  }
  .align-center {
    text-align: center !important;
  }
  .labor-font-l {
    font-size: 20px !important;
  }

  .labor-width-a{
    width: 5%;
  }
  .labor-width-b{
    width: 6%;
  }
  .labor-width-c{
    width: 7%;
  }
  .labor-width-d{
    width: 8%;
  }
  .labor-width-e{
    width: 9%;
  }
  .labor-width-k{
    width: 24%;
  }
  .labor-width-l{
    width: 26%;
  }

  .h95vh {
    height: 95vh;
  }
  .modal-content-setting {
    height: 510px !important;
  }
  .modal-body {
    max-height: none !important;
  }
  .ifdialog {
    width: 100%;
    height: 100%;
    border: 0;
  }
  .form-check {
    margin-right: 10px;
  }
  .datetimepicker-input {
    background: #fff !important;
  }
  .monthinput {
    width: 200px;
    height: 30px;
  }
  .chart-header {
    font-size: 1.9rem;
    padding-left: 100px;
  }
  .bg-red-color {
    background-color: #f08080;
  }
  .bg-yellow-color {
    background-color: #f0e68c;
  }
  .labor-detail-hedder-font {
    margin-right: 15px;
    font-size: 1.5rem;
    display: inline-block;
    margin-top: 10px;
  }
  #graph {
    display: none;
  }
}
