
.login-page {
    background-color: #f0ffff;
    .card-primary.card-outline {
        border-top: none;
    }
    .login-logo a img{
        width: 75%;
        height: 75%;
        padding-bottom: 50px;
    }
    .login-box, .register-box {
        width: 520px;
    }
    @media screen and (max-width: 576px) {
        .login-box, .register-box {
            width: 80%;
        }
    }
    .login-card-body .input-group .input-group-text,
    .register-card-body .input-group .input-group-text {
        background-color: transparent;
        border-bottom-right-radius: 0.25rem !important;
        border-left: 0;
        border-top-right-radius: 0.25rem !important;
        color: #777;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .show-password-btn:hover {
        cursor: pointer;
    }
}
