.company-page {
    #company-csv-setting-modal {
        #company-csv-setting-modal-csv-error {
            width: 100%;
            max-height: 150px;
            list-style-type: none;
            color: $danger;
            font-size: 0.9rem;
            overflow-y: scroll;
            border: 1px solid $gray-500;
        }
        #company-csv-setting-modal-csv-submit-message {
            color: $green;
            font-size: 0.9rem;
        }
    }

    .password_form {
        .input-group .input-group-text {
            background-color: transparent;
            border-bottom-right-radius: 0.25rem !important;
            border-left: 0;
            border-top-right-radius: 0.25rem !important;
            color: #777;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }

        .input-group .form-control {
            border-right: 0;
        }

        .show-password-btn:hover {
            cursor: pointer;
        }
    }
}
