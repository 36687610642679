.violation-page {
    .header-top{
        top:-1px;
    }
    // 虫眼鏡を消す
    .nav-link[data-widget="navbar-search"] {
        display: none;
    }
    .navbar-search-block {
        display: none;
    }

    //印刷ボタン
    .dropdown-menu {
        margin-right: 77px;
    }

    /** 違反歴画面のカレンダー表示設定 */
    .violation-calendar-text-position {
        text-align: center;
    }
    .violation-add-btn {
        text-align: end;
    }
    .summary-block {
        .rate {
            display: inline-block;
            width: 124px;
            height:124px;
            background-repeat: no-repeat;
            background-size: 124px 124px;
            background-position: 0 0;
            &.no-occurred-days {
                background-image: url("/assets/img/violation/no_days.png");
            }
            &.occurred-count {
                background-image: url("/assets/img/violation/count.png");
            }
            &.occurred-rate {
                background-image: url("/assets/img/violation/rate.png");
            }
            .summary-value {
                text-align: center;
                font-size: 3rem;
                padding-top: 2rem;
            }
        }
        @media screen and (max-width: 767px) {
            .rate {
                width: 100px;
                height:100px;
                background-size: 100px 100px;
                .summary-value {
                    text-align: center;
                    font-size: 2rem;
                    padding-top: 1.8rem;
                }
            }
        }
    }

    /* モーダル */
    #violation-detail-modal {
        .modal-violation {
            max-width: inherit;
            position: relative;
            width: 80%;
        }
        .modal-body {
            max-height: 800px;
            height: 80vh;
            margin: 15px;
            overflow-y: auto;
        }
        @media screen and (max-width: 575px) {
            .modal-violation {
                width: auto;
            }
        }
    }

    /* 違反歴詳細モーダル */
    .violation-day-table {
        thead th {
            padding: 0.5em 0;
            vertical-align: middle;
            text-align: center;
            background-color: $bss_color_logo_blue;
            color: $bss_color_background;
            width:calc(100% / 25);
        }
        tbody td {
            padding: 0.5em 0;
            vertical-align: middle;
            text-align: center;
            &.error {
                background-color: $table-bg-color-pink;
            }
        }
    }
}

/*
   違反歴登録モーダル設定
   車両台帳、車両台帳でも使用するためglobal設定する
   id重複注意
*/
#violation-setting-modal {
    .modal-violation {
        max-width: inherit;
        position: relative;
        width: 80%;
        .violation_kind-close {
            cursor: pointer !important;
            i {
                color: gray;
            }
        }
    }
    .modal-body {
        max-height: 800px;
        height: 80vh;
        overflow-y: auto;
    }
    @media screen and (max-width: 575px) {
        .modal-violation {
            width: auto;
        }
    }
}
