// instruction
.instruction-page {
    .align-right {
        text-align: right !important;
    }
    .align-left {
        text-align: left !important;
    }
    .align-center {
        text-align: center !important;
    }
    //印刷ボタン運行管理者
    .dropdown-menu {
        margin-right: 145px;
    }

    table {
        //指示事項内容管理一覧の表示幅を設定
        .instruction-width-a{
            width: 15%;
        }
        .instruction-width-b{
            width: 15%;
        }
        .instruction-width-c{
            width: 10%;
        }
        .instruction-width-d{
            width: 35%;
        }
        .instruction-width-3{
            width: 25%;
        }
    }
    .total-print-btn-container {
        margin-left: 3px;
    }
    #instruction-delete-modal {
        .announce {
            margin-bottom: 0px;
        }
        .errmsg {
            color: red;
            font-weight: bold;
        }
    }
}
