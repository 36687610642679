
.main-header {
    max-height: 54px;
    img {
        max-height: 37px;
    }
    .phone-number {
        font-size: 21px;
        font-weight: 600;
        margin-bottom: 0;
        line-height: 21px;
        color: $bss_color_logo_blue;
        span {
            font-size: 12px;
        }
    }
    .call-center{
        font-size:12px;
        line-height: 12px;
        margin-bottom: 0;
    }
}
.sub-header {
    background-color: $bss_color_logo_blue;
    background-image: linear-gradient(to bottom, #3C8DE0, #005BC0);
    img {
        max-height: 37px;
    }
}

.navbar-primary.navbar-light .form-control-navbar:focus, .navbar-primary.navbar-light .form-control-navbar:focus + .input-group-append .btn-navbar,
.navbar-primary.navbar-light .form-control-navbar, .navbar-primary.navbar-light .form-control-navbar + .input-group-append > .btn-navbar {
    background-color: $white;
}

.navbar {
    justify-content: initial; /* override */
}

.sub-header .navbar-nav .nav-link {
    color: $white;
    &:hover {
        color: $white;
    }
}
.navbar-search-block {
    width:  300px;
    position: unset !important; /* override */
    margin-bottom: 0;
    .input-group input {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 580px) {
    .navbar-search-block {
        width: 250px;
        margin-top: 0;
        margin-bottom: 0.45rem;
    }
    #nav1{
        order:1;
    }
    #nav2{
        order:3;
    }
    #nav3{
        order:2;
    }
}

#page-menu-config {
    font-size: 16px;
    font-weight: 700;
    color: #FFF;
    img {
        height: 30px;
        margin-top: -10px;
        margin-right: 5px;
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
    }
}
.affiliation-link {
    background-color: $white;
    height: 35px;
    margin-left: 22px;
    label {
        display: block;
        height: 10px;
        font-size: 8px;
        margin: -6px 5px 0 2px;
        font-weight: normal !important;
    }
    img, span, .nav-link {
        height: 1rem;
        font-size: 13px;
        vertical-align: middle;
    }
    &.first {
        margin-left: 0;
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        width: 34px;
        height: 35px;
        right: -34px;
        top: 0;
        border-top: 17px solid transparent;
        border-right: 17px solid transparent;
        border-bottom: 17px solid transparent;
        border-left: 17px solid $white;
    }
    &:not(.first)::before {
        content: "";
        display: block;
        position: absolute;
        width: 34px;
        height: 35px;
        left: -17px;
        top: 0;
        border-top: 17px solid $white;
        border-right: 17px solid transparent;
        border-bottom: 17px solid $white;
        border-left: 17px solid transparent;
    }
    .nav-link {
        color: $primary !important;
        font-weight: bold;
        padding-left: 0.75rem !important;
        padding-right: 0.5rem !important;
        span {
            max-width: 320px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    @media screen and (max-width: 1199px) {
        .nav-link {
            span {
                max-width: 220px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .nav-link {
            span {
                max-width: 150px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .nav-link {
            span {
                max-width: 110px;
            }
        }
    }
    .dropdown-toggle::after {
        content: none;
    }
}
.affiliation-menu {
    .affiliation-select {
        position: absolute;
        z-index: 10;
        .selection {
            visibility: hidden;
        }
    }
}
.affiliation-select-dropdown {
    margin-top: -38px;
    .select2-results .select2-results__options {
        max-height: 600px;
        overflow-y: auto;
    }
}
