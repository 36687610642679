// improvement
.improvement-page {
    .header-top {
        top:-1px;
    }
    .align-right {
        text-align: right !important;
    }
    .align-left {
        text-align: left !important;
    }
    .align-center {
        text-align: center !important;
    }
    .detail-overflow {
        max-height: 75vh;
    }

    @media screen and (max-width: 996px) {
        .detail-overflow {
            max-height: 65vh;
        }
    }
    #search-form-simulate .fas {
        font-size: 25px;
    }
    .monthly, .yearly {
        .improvement-user_code,
        .improvement-per-month {
            width: 5%;
        }
        .improvement-row-spans {
            width: 7%;
        }
        .improvement-columns {
            width: 7%;
        }
        th {
            font-size: 0.9rem;
        }
        td {
            text-align: right;
        }
    }
    .monthly {
        .improvement-user_name {
            width: 14%;
        }
    }
    .yearly {
        .improvement-user_name {
            width: 26%;
        }
        .improvement-yearly_rec_time {
            width: 9%;
        }
    }
    .modal-body {
        max-height: none !important;
    }
    .form-check {
        margin-right: 10px;
    }
    .datetimepicker-input {
        background: #fff !important;
    }
    .chart-header {
        font-size: 1.9rem;
        padding-left: 100px;
    }
    .red-color {
        color: red
    }
    .blue-color {
        color: blue
    }
    .bg-red-color {
        background-color: #f08080;
    }
    .bg-yellow-color {
        background-color: #f0e68c;
    }
    .improvement-detail-header {
        span {
            margin-right: 15px;
            font-size: 1.5rem;
            display: inline-block;
            line-height: 2rem;
        }
    }
    #graph {
        display: none;
    }
    #improvement-config-modal-form, 
    #improvement-config-modal-until-2023-form {
        h5 {
            font-weight: bold;
        }
        h5::before {
                content: '◆';
        }
    }
}
