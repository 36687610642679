.link-page {
    .header-top{
        top:-1px;
    }
    ul#link-list {
        list-style: none;
        padding: 5px;

        li {
            margin:  0 0 10px 0;
            padding: 0 10px 10px 10px;
            border-bottom: 1px solid #cecece;

            i {
                color: #aaa;
            }
        }
        a {
            font-weight: bold;
        }
    }

    table {
        word-break: break-all;
        word-wrap: break-word;
    }

    #link-setting-modal-form {
        textarea {
            min-height: 100px;
        }
        div.file-delete {
            display: none;
        }
    }
}
