.external_history-page {
    .header-top {
        top:-1px;
    }
    .modal-external_history {
        max-width: inherit;
        position: relative;
        width: 80%;
    }
    #external_history-detail-modal {
        .modal-dialog {
            max-width: 1600px;
        }
        .modal-body {
            overflow-y: hidden;
            max-height: 900px;
        }
        .modal-content {
            height: 90vh;
        }
        #content {
            height: 100%;
            .tab-pane {
                min-height: 200px;
            }
            #detail-tabs {
                div.nav-tabs {
                    flex-direction: column;
                    border-left: 1px solid #dee2e6;
                    border-bottom: none;
                }
            }
        }
        @media screen and (max-width: 1199px) {
            #content {
                .reverse {
                    display: flex;
                    flex-direction: column-reverse;
                    .tab-content {
                        margin-top: 20px;
                    }
                }
                #detail-tabs {
                    padding: 0 !important;
                    div.nav-tabs {
                        flex-direction: unset;
                        border-bottom: 1px solid #dee2e6;
                    }
                }
            }
        }
        #left-side {
            min-height: 100%;
            margin-bottom: 20px;
            border-right: 1px solid #dee2e6;
        }
        @media screen and (max-width: 1199px) {
            #left-side {
                min-height: unset;
                border-right: none;
            }
        }
        #right-side {
            height: 100%;
        }
        #basic.card{
            margin-right: 9px;
            .row.content {
                margin: 10px;
            }
            .info {
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        .json {
            overflow-y: scroll;
            background-color: #272727;
            border-color: #4c4c4c;
            .row {
                max-height: 700px;
            }
            .json-text-green {
                color: #95e695;
            }
            .json-text-yellow {
                color: #e8e86f;
            }
        }
        dt {
            margin-top: 1px;
            margin-bottom: 1px;
        }
        dd {
            margin-bottom: 0px;
        }
        th.error, td.error {
            font-weight: bold;
            color: red;
        }
        .nav-tabs {
            line-height: 1.2rem;
            .nav-link {
                border-color: #dee2e6 #dee2e6 #dee2e6 transparent;
                &.active {
                    background-color: $blue;
                    color: $white;
                }
            }
        }
    }
}