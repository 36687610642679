.accident-page {
    .header-top{
        top:-1px;
    }
    // 虫眼鏡を消す
    .nav-link[data-widget="navbar-search"] {
        display: none;
    }
    .navbar-search-block {
        display: none;
    }

    //印刷ボタン
    .dropdown-menu {
        margin-right: 93px;
    }

    /** 事故記録画面のカレンダー表示設定 */
    .accident-calendar-text-position {
        text-align: center;
    }
    .accident-add-btn {
        text-align: end;
    }
    .summary-block {
        position: relative;
        .rate {
            display: inline-block;
            width: 124px;
            height:124px;
            background-repeat: no-repeat;
            background-size: 124px 124px;
            background-position: 0 0;
            &.no-occurred-days {
                font-size: 3rem !important;
                background-image: url("/assets/img/accident/no_days.png");
            }
            &.all {
                font-size: 2rem !important;
                background-image: url("/assets/img/accident/all.png");
            }
            &.perpetrator {
                font-size: 2rem !important;
                background-image: url("/assets/img/accident/perpetrator.png");
            }
            &.victim {
                font-size: 2rem !important;
                background-image: url("/assets/img/accident/victim.png");
            }
            .summary-value {
                position: relative;
                text-align: center;
                margin-top: 25px;
                .summary-value-count,
                .summary-value-rate {
                    position: absolute;
                    width: 100%;
                }
                .summary-value-count {
                    top: 0;
                }
                .summary-value-rate {
                    top: 2.5rem;
                }
            }
        }
        @media screen and (max-width: 767px) {
            .rate {
                width: 100px;
                height:100px;
                background-size: 100px 100px;
                &.no-occurred-days {
                    font-size: 2rem !important;
                }
                &.all {
                    font-size: 1.8rem !important;
                }
                &.perpetrator {
                    font-size: 1.8rem !important;
                }
                &.victim {
                    font-size: 1.8rem !important;
                }
                .summary-value {
                    margin-top: 20px;
                    .summary-value-rate {
                        top: 2rem;
                    }
                }
            }
        }
    }
    .accident-day-table {
        thead th {
            padding: 0.5em 0;
            vertical-align: middle;
            text-align: center;
            background-color: $bss_color_logo_blue;
            color: $bss_color_background;
            width:calc(100% / 25);
        }
        tbody td {
            padding: 0.5em 0;
            vertical-align: middle;
            text-align: center;
            &.error {
                background-color: $table-bg-color-pink;
            }
        }
    }
    .notice-count-block {
        top: -33px;
    }


    /* 事故記録詳細モーダル */
    #accident-detail-modal {
        .modal-accident {
            max-width: inherit;
            position: relative;
            width: 80%;
        }
        .modal-body {
            max-height: 800px;
            height: 80vh;
            margin: 15px;
            overflow-y: auto;
        }
        @media screen and (max-width: 575px) {
            .modal-accident {
                width: auto;
            }
        }
    }

    #accident-config-modal {
        .modal-content {
            min-height: 550px;
        }
    }
}
/*
   事故記録登録モーダル設定
   車両台帳、車両台帳でも使用するためglobal設定する
   id重複注意
*/
#accident-setting-modal {
    .modal-accident {
        max-width: inherit;
        position: relative;
        width: 90%;
    }
    .modal-body:not(.files-dispose-modal .modal-body) {
        max-height: 800px;
        height: 80vh;
        overflow-y: auto;
    }
    input#accident-setting-modal-count_of_death,
    input#accident-setting-modal-count_of_serious,
    input#accident-setting-modal-count_of_minor {
        width: 100px;
        margin-left: 1em;
    }
    input.municipality {
        width: 300px
    }
    input.address {
        width: 400px
    }
    .accident_image-preview:not(.modal-files .accident_image-preview),
    .site_image-preview:not(.modal-files .site_image-preview),
    .car_body_image-preview:not(.modal-files .car_body_image-preview) {
        width: 200px;
        position: absolute;
        left: 120px;
        top: 0;
        z-index: 999;
    }
    @media screen and (max-width: 575px) {
        .modal-accident {
            width: auto;
        }
    }
    .files-error {
        padding-top: 30px;
    }
}
