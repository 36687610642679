/**
  for news-release on global menu
 */
.badge-newer {
    position: absolute;
    display: inline-block;
    top: 1px;
    right: 6px;
    z-index: 10;
    padding: 2px 4.5px 1px;
    border-radius: 20px;
    font-size: 0.75rem;
    line-height: 1;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
    color: #FFF;
    background-color: #dc3545;
}

.this-newer {
    font-weight: normal;
}
.this-newer::after {
    display: inline-block;
    content: "NEW";
    color: #dc3545;
    font-size: 0.8rem;
    font-weight: bold;
    vertical-align: middle;
    margin-left: 5px;
}

.news_release-item {
    font-size: 0.85rem;
    white-space: normal;
}

.go-list a {
    font-weight: normal;
}

.go-list a::before {
    display: inline-block;
    content: "\f061";
    font-family: "Font Awesome 5 Free","Font Awesome 5 Brands";
    font-size: 0.8rem;
    font-weight: bold;
    vertical-align: middle;
    margin-right: 5px;
}


/**
  for news_release-page
 */
.news_release-page {
    .header-top{
        top:-1px;
    }
    ul#news_release-list {
        list-style: none;
        padding: 5px;

        li {
            margin:  0 0 10px 0;
            padding: 0 10px 10px 10px;
            border-bottom: 1px solid #cecece;

            i {
                color: #aaa;
            }
        }
        li:last-child {
            border-bottom: none;
        }
        a {
            font-weight: bold;
            p {
                font-weight: normal;
            }
        }
    }

    table {
        word-break: break-all;
        word-wrap: break-word;
    }

    #news_release-setting-modal {
        textarea {
            min-height: 100px;
        }
    }
}
