/**
  for news-release on global menu
 */
.text-category {
    font-size: 0.65rem;
    border-radius: 3px;
    color: #FFF;
    padding: 0.15rem;
}
.text-category-1 {
    background-color: #17a2b8 !important;
}
.text-category-2 {
    background-color: #08C403 !important;
}
.text-category-3 {
    background-color: #DA1C1C !important;
}
.client_download-item {
    white-space: normal;
}

/**
  for client_download-page
 */
.client_download-page {
    table {
        word-break: break-all;
        word-wrap: break-word;
    }

    #client_download-setting-modal {
        textarea {
            min-height: 100px;
        }
    }
}
