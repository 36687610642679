.guidance-page {
    .header-top{
        top:-1px;
    }
    // 虫眼鏡を消す
    .nav-link[data-widget="navbar-search"] {
        display: none;
    }
    .navbar-search-block {
        display: none;
    }

    //印刷ボタン
    .dropdown-menu {
        margin-right: 93px;
    }

    /** 指導記録画面のカレンダー表示設定 */
    .guidance-calendar-text-position {
        text-align: center;
    }

    .guidance-search-form-month {
        .month-picker {
            height: 60px;
            text-align: center;
        }
        .back, .forward, .current {
            width: 25px;
            height: 25px;
            line-height: 24px;
            bottom: 5px;
        }
        .current {
            bottom: 35px;
        }
    }
    .guidance-add-btn {
        text-align: end;
    }

    /* モーダル */
    .modal-guidance {
        max-width: inherit;
        position: relative;
        width: 80%;
        select.before-join, select.after-join {
            min-height: 150px;
            max-height: 250px;
        }
        select.after-join {
            border: 1px solid $blue;
        }
        #add, #remove {
            width: 100%;
            display: block;
        }
    }
    .modal-body {
        max-height: 800px;
        height: 80vh;
        margin: 15px;
        overflow-y: auto;
        /* 指導記録詳細モーダル */
        .guidance-day-table {
            thead th {
                padding: 0.5em 0;
                vertical-align: middle;
                text-align: center;
                background-color: $bss_color_logo_blue;
                color: $bss_color_background;
                width:calc(100% / 25);
                &.executed_at_datetime {
                    width:calc(100% / 25 * 2);
                }
            }
            tbody td {
                padding: 0.5em 0;
                vertical-align: middle;
                text-align: center;
                &.error {
                    background-color: $table-bg-color-pink;
                }
            }
        }
    }

    #guidance-setting-modal {
        .guidance_file {
            margin-left: 30px;
        }
    }

    #guidance-participants-modal,
    #guidance-delete-modal {
        .modal-body {
            height: unset;
        }
    }
    .btn-app:active, .btn-app:focus {
        box-shadow: none;
    }
    @media screen and (max-width: 768px) {
        .modal-guidance {
            #add, #remove {
                width: unset;
                display: unset;
                span.right-arrow, span.left-arrow {
                    display: inline-block;
                    transform: rotate(90deg);
                }
            }
        }
    }
    @media screen and (max-width: 576px) {
        .modal-guidance {
            width: unset;
        }
    }
}
