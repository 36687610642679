.equipment_classification-page,
.equipment-page {
    // 虫眼鏡を消す
    .nav-link[data-widget="navbar-search"] {
        display: none;
    }
    .navbar-search-block {
        display: none;
    }
    /** サイドメニュー */
    #side-area {
        position: relative;
        width: 18%;
        #side-bar {
            height: 80vh;
            min-height: 80vh;
            overflow: auto;
            a:hover {
                text-decoration: underline;
            }
        }
        .ui-resizable-e {
            background: linear-gradient(90deg, #e0ddda, #aeacaf);
            border-top-right-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
            width:1rem;
            right: -1rem;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            font-size: 0.5rem;
            color: grey;
        }
        .ui-resizable-e:before {
            position: absolute;
            top: 50%;
            left: 25%;
            content: '\f0c9';
        }
        .list-group-item > .badge {
            float: right;
            display: inline-block;
            min-width: 10px;
            padding: 3px 7px;
            font-size: 12px;
            font-weight: bold;
            line-height: 1;
            color: #fff;
            text-align: center;
            white-space: nowrap;
            vertical-align: baseline;
            background-color: #777;
            border-radius: 10px;
        }
    }
    #contents {
        position: relative;
        width: 80%;
        min-width: 500px;
        padding-right: 7.5px;
        padding-left: 7.5px;
        margin-left: 1rem;
    }

    @media screen and (max-width: 991px) {
        #side-area {
            position: relative;
            width: 100%;
            #side-bar {
                height: auto;
                min-height: auto;
            }
            .ui-resizable-e {
                display: none;
            }
        }
        #contents {
            position: relative;
            width: 100%;
            min-width: unset;
            padding-right: 2.5px;
            padding-left: 2.5px;
            margin-left: 0;
        }
    }
}
