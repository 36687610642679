.workflow-page {
    .card-footer{
        background-color: white;
        margin-top: 201px;
        margin-bottom: 23px;
        padding: 0.75rem 1.25rem;
    }
    .card-footer-list{
        background-color: white;
        margin-top: 23px;
        margin-bottom: 23px;
        padding: 0.75rem 1.25rem;
    }
    .card-footer-sub{
        background-color: white;
        margin-top: 34px;
        margin-bottom: 23px;
        padding: 0.75rem 1.25rem;
    }

    .schedule-list-row{
        display:flex;
        flex-wrap: nowrap;
        height: 100%;
    }
    .schedule-list-header{
        width: 100%;
        padding: 7px;
        border: 1px solid;
        text-align: center;
        font-size: 1em;
        font-weight: 800;
        background-color: #0055a0;
        color: white;
    }
    .schedule-list{
        width: 14%;
        padding-top: 0;
        padding-bottom: 15px;
        border: 1px solid #c1c6ca;

        .list-font{
            font-weight: 800;
            margin-left: 7px;
            margin-top: 20px;
        }
    }
    .diag-list-gap{
        position: relative;
        height: auto;
        margin: auto 0;
        flex: 0 0 3.333333%;
        .diag-list-gap-img{
            display:flex;
            flex-direction: column;
            margin: 93% 0;
            width: 48px;
            height: 48px;
        }
    }
    .diag-calendar {
        width: 100%;
        height: 100%;
        margin-bottom: 0 !important;
        thead tr th {
            font-size: 1em;
            width: calc(100% / 7);
            border: 1px solid #c1c6ca;
        }
        tbody tr td {
            height: 120px;
            vertical-align: top;
            border: 1px solid #c1c6ca;
            p {
                font-size: 2em;
            }
            @media screen and (max-width: 767px) {
                p {
                    font-size: 1.5rem;
                }
            }
        }
    }
    .modal-body {
        overflow-y: auto;
        max-height: 600px;
    }
    .modal-body-list {
        overflow-y: visible;
        height: 600px;
        max-height: 600px;
    }
    .modal-body-schedule{
        overflow-y: hidden;
        max-height: 375px;
    }
    .datepicker table th, .datepicker table td{
        font-size: 1.0em;
    }
    .plan {
        position: relative;
        border-radius: 4px;
        background: #4c87c7;
        color: #fff;
        font-size: 15px;
        width: 100%;
        padding: 6px 6px;
        margin-top: 2px;
        img {
            position: absolute;
            right: 5px;
            top: 5px;
            width: 24px;
            margin: 0 0 5px 5px;
        }
    }
    .person {
        border-radius: 4px;
        background: #4c87c7;
        color: #fff;
        font-size: 15px;
        text-align: center;
        padding: 6px;
        width: 95%;
        margin: 0 0 0.5rem 6px;
    }
    .spec {
        background: #C74E4C;
    }
    .spec-close {
        background-color: $gray-600;
        pointer-events: none;
    }
    .col-form-label {
        font-size: 0.95em;
    }
    .plan {
        cursor: pointer;
    }
    .invalid-feedback {
        margin: 0;
    }
    .diag-list th{
        text-align: center;
    }
    .diag-list thead tr, .diag-list tbody tr td {
        font-size: 0.9em;
        border: 1px solid #c1c6ca;
        text-align: left;
    }
    .datepicker table th, .datepicker table td{
        font-size: 1.0em;
    }
    textarea.form-control {
        min-height: 160px;
        height: 160px;
    }
    .bg-lightred {
        background: #fff2f8 !important;
    }
    .bg-lightsilver {
        background: $gray-200 !important;
    }
    .list-table-tr-color{
        background: white !important;
    }

    .modal-min{
        min-height: 100px;
    }

    .date-wavy {
        margin-top : 8px;
    }

    .error-message {
        white-space:initial;
        word-wrap:break-word;
        max-width:100%;
        color:red;
    }

}
