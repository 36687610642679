
.paid_holiday_config-page {
    
    #paid_holiday_config_list {
        .status {
            font-weight: bold;
            color: $gray-600;
            &.enable {
                color: $success;
            }
        }
    }
    #paid_holiday_config-setting-modal {
        #proportional-grant {
            .header {
                text-align: center;
                div {
                    background-color: $gray-200;
                    &:last-child {
                        border-left: 3px solid $white;
                    }
                    label {
                        margin-bottom: 0px;
                    }
                }
            }
            .proportional-grant-item {
                border-bottom: 1px solid $gray-300;
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}